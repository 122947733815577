<template>
  <v-card>
    <v-card-title>
      <span class="headline">Passwords Debug</span>
    </v-card-title>

    <v-simple-table class="pa-6 ma-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Password
            </th>
            <th class="text-left">
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in debugPasswordList" :key="item">
            <td>{{ item.debug_password }}</td>
            <td>{{ item.created_at }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close()">
        Fechar
      </v-btn>
    </v-card-actions>

  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "debug-passwords-card",
  props: ["license_id"],
  data: () => ({
    debugPasswordList: [],
  }),
  watch: {

  },
  created() {
    this.fetchDebugPasswords();
  },
  methods: {
    ...mapActions("license", ["debugPasswords"]),
    fetchDebugPasswords() {
      var params = {
        license_id: this.license_id,
      };

      this.debugPasswords(params).then(response => {
        if (response.data.attributes != null) {
          this.debugPasswordList = response.data.attributes
        }
      });
    },
    close() {
      this.debugPasswordList = [];
      this.$emit("dialogDebugPasswordClosed", 0);
    },
  }
};
</script>
