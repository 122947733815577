<template>
  <div>
    <v-form>
      <v-card>
        <div class="pt-4 pl-4">
          <v-icon>
            mdi-account
          </v-icon>
          <h3 class="headline mb-2">
            Alterar informações de utilizador
          </h3>
        </div>

        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="user.email"
                :error-messages="errors['email']"
                label="Email"
                disabled
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="user.name"
                :error-messages="errors['name']"
                label="Nome"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="user.company"
                :error-messages="errors['company']"
                label="Empresa"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="user.country"
                :error-messages="errors['country']"
                label="País"
                disabled
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="user.city"
                :error-messages="errors['city']"
                label="Cidade"
                disabled
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="user.postalcode"
                :error-messages="errors['postalcode']"
                label="Código Postal"
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.address"
                :error-messages="errors['address']"
                label="Endereço"
                disabled
              />
            </v-col>
          </v-row>
          <div class="d-flex justify-space-between">
            <v-spacer></v-spacer>
            <v-btn @click="updateProfile" color="primary">
              Atualizar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card>
      <div class="pt-4 pl-4">
        <v-icon>
          mdi-lock
        </v-icon>
        <h3 class="headline mb-2">
          Alterar password
        </h3>
      </div>
      <v-card-text>
        <div>
          <v-form v-model="passwordsValid" class="pt-4">
            <v-flex offset-md-3 xs6>
              <v-text-field
                v-model="oldPassword"
                label="Password antiga"
                :error-messages="errors['old_password']"
                :append-icon="oldPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (oldPasswordValue = !oldPasswordValue)"
                :type="oldPasswordValue ? 'password' : 'text'"
                :rules="[rules.exists]"
              ></v-text-field>
            </v-flex>
            <v-flex offset-md-3 xs6>
              <v-text-field
                v-model="newPassword"
                label="Nova password"
                hint="Password válida"
                :append-icon="newPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (newPasswordValue = !newPasswordValue)"
                :type="newPasswordValue ? 'password' : 'text'"
                :rules="[rules.password]"
              ></v-text-field>
            </v-flex>
            <v-flex offset-md-3 xs6>
              <v-text-field
                v-model="repeatPassword"
                label="Repetir password"
                hint="Password corresponde"
                :append-icon="repeatPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="
                  () => (repeatPasswordValue = !repeatPasswordValue)
                "
                :type="repeatPasswordValue ? 'password' : 'text'"
                :rules="[rules.matching]"
              ></v-text-field>
            </v-flex>
            <div class="d-flex justify-space-between">
              <v-spacer></v-spacer>
              <v-btn
                @click="updatePassword"
                :disabled="!passwordsValid"
                color="primary"
              >
                Alterar
              </v-btn>
            </div>
          </v-form>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "edit-profile-form",
  data() {
    return {
      errors: [],
      user: {
        username: null,
        email: null,
        name: null,
        company: null,
        address: null,
        city: null,
        country: null,
        postalcode: null,
        personalnotes: ""
      },
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      oldPasswordValue: true,
      newPasswordValue: true,
      repeatPasswordValue: true,
      passwordsValid: false,
      overlay: false,
      passwordPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      rules: {
        exists: value => {
          return (
            value.length >= 6 || "A password inserida aparenta ser inválida."
          );
        },
        password: value => {
          return (
            this.passwordPattern.test(value) ||
            "Min. 8 caracteres com pelo menos uma letra maiúscula, minúscula e um número."
          );
        },
        matching: value => {
          return (
            value == this.newPassword || "As passwords inseridas não coincidem"
          );
        }
      }
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("auth", ["fetchUser", "updateUser", "passwordChange"]),
    initialize() {
      this.errors = [];
      this.fetchUser().then(response => {
        if(response.data.attributes != null){
          // Dados obtidos
          this.user = response.data.attributes;
        }else{
          // Existem erros
          var errors = this.$store.state.errors;
          if(errors.length > 0){
            this.errors = errors;
          }
        }
        this.$store.commit("setErrors", []);
      }).catch(err => {
          this.errors = err;
      });
    },

    updateProfile() {
      this.errors = [];
      this.updateUser(this.user).then(response => {
        var errors = this.$store.state.errors;
        if (errors.length === 0) {
          // Dados obtidos
          this.user = JSON.parse(JSON.stringify(response.data.attributes));
        } else {
          // Existem erros
          this.errors = JSON.parse(JSON.stringify(errors));
        }
        this.$store.commit("setErrors", []);
      });
    },
    updatePassword() {
      this.errors = {};
      this.overlay = true;
      // Criar payload
      var passwordChangePayload = {
        old_password: this.oldPassword,
        new_password: this.newPassword
      };

      // Enviar request
      this.passwordChange(passwordChangePayload)
        .then(res => {
          if (res.status == 200) {
            // Password alterada com sucesso
            this.$router.go();
          } else {
            // Ocorreram erros
            this.overlay = false;
            this.oldPassword = "";
            this.errors.old_password = "Erro desconhecido";
          }
        })
        .catch(err => {
          // Ocorreram erros
          this.overlay = false;
          this.oldPassword = "";
          if (err.response.data != undefined) {
            this.errors.old_password = err.response.data.error;
          } else {
            this.errors.old_password = "Erro desconhecido";
          }
        });
    }
  }
};
</script>
