<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <!-- <md-list-item href="#/notifications" class="dropdown">
              <drop-down>
                <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
                  <i class="material-icons">notifications</i>
                  <span class="notification">5</span>
                  <p class="hidden-lg hidden-md">Notifications</p>
                </a>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a href="#">Mike John responded to your email</a></li>
                  <li><a href="#">You have 5 new tasks</a></li>
                  <li><a href="#">You're now friend with Andrew</a></li>
                  <li><a href="#">Another Notification</a></li>
                  <li><a href="#">Another One</a></li>
                </ul>
              </drop-down>
            </md-list-item> -->

            <!-- NOTIFICATION
            <li class="md-list-item">
              <a
                href="#/notifications"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down>
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>
            -->

            <!--
            <md-list-item href="#/notifications">
              <i class="material-icons">notifications</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
            -->
            <md-list-item>
            <div>
              <p v-if="user.data.role == 'admin' || user.data.role == 'client'" class="ma-0 text-right">{{ user.data.name }}</p>
              <p v-else class="ma-0 text-right">{{ user.data.company }}</p>
              <h6 class="ma-0 text-right">{{ userRole }}</h6>
            </div>
            </md-list-item>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <md-list-item href="#/" 
                      v-bind="attrs"
                      v-on="on" 
                      @click="logoutUser()">
                  <i class="material-icons">logout</i>
                  <p class="hidden-lg hidden-md">Logout</p>
                </md-list-item>
              </template>
              <span>Terminar sessão</span>
            </v-tooltip>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed : {
    ...mapGetters("auth", { user: "user" }),
    userRole() {
      if(this.user.data.role == 'admin'){
        return 'Administrador';
      } else if(this.user.data.role == 'provider'){
        return 'Parceiro';
      } else if(this.user.data.role == 'client'){
        return 'Utilizador';
      } else if(this.user.data.role == 'accountant'){
        return 'Contabilista';
      }
      return '';
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    logoutUser() {
      this.logout();
      this.$nextTick(() => {
        this.$router.push("/auth/signin/identifier");
      });
    }
  }
};
</script>
