import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth";
import admin from "./admin";
import backup from "./backup";
import client from "./client";
import documentstorage from "./documentstorage";
import license from "./license";
import partner from "./partner";
import accountant from "./accountant";
import release from "./release";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: new Array(),
  },

  getters: {
    errors: state => state.errors
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    }
  },

  actions: {},

  modules: {
    auth,
    admin,
    backup,
    client,
    documentstorage,
    license,
    partner,
    accountant,
    release
  },

  plugins: [createPersistedState()]
});
