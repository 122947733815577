<template>
  <v-layout style="height: 80vh">
    <v-flex style="overflow: auto">
      <v-card style="width: 75vw; margin: auto">
        <v-tabs>
          <v-tab v-for="tab in tabNames" :key="tab">
            {{ tab }}
          </v-tab>
          <v-tab-item style="height: 74vh">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card color="#3F51B5" dark>
                    <v-card-title class="headline"> OCTA GEST </v-card-title>
                    <v-card-text class="text--white">
                      <div>
                        Versão atual: {{ currentOctaGestRelease.version }}
                      </div>
                      <div>Data: {{ currentOctaGestRelease.date }}</div>
                      <div>Stage: {{ currentOctaGestRelease.stage }}</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click="openDialog('OctaGest')">
                        Nova release
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card color="#9C27B0" dark>
                    <v-card-title class="headline"> OCTA RETAIL </v-card-title>
                    <v-card-text class="text--white">
                      <div>
                        Versão atual: {{ currentOctaPosRelease.version }}
                      </div>
                      <div>Data: {{ currentOctaPosRelease.date }}</div>
                      <div>Stage: {{ currentOctaPosRelease.stage }}</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click="openDialog('OctaPos')">
                        Nova release
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!--
                <v-col cols="4">
                  <v-card color="#F44336" dark>
                    <v-card-title class="headline"> DEV </v-card-title>
                    <v-card-text class="text--white">
                      <div>Versão atual: {{ currentDevRelease.version }}</div>
                      <div>Data: {{ currentDevRelease.date }}</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click="openDialog('dev')">
                        Nova release
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                -->
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-data-table height="36vh" fixed-header :headers="headers" :items="releases" sort-by="version"
                    sort-desc :loading="releasesLoading" loading-text="A carregar dados..." :footer-props="{
                      'items-per-page-options': [10, 25, 50]
                    }">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-dialog v-model="dialog" max-width="760px" persistent>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="4">
                                    <v-select label="Produto" v-model="editedRelease.application"
                                      v-bind:disabled="editedIndex != -1" :items="octaReleases" item-value="key"
                                      item-text="value" prepend-icon="mdi-tag"></v-select>
                                  </v-col>
                                  <v-col cols="4">
                                    <v-text-field v-model="editedRelease.version" :error-messages="errors['version']"
                                      label="Versão" v-bind:disabled="editedIndex != -1" v-mask="'#.####.##.#'">
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="4">
                                    <v-select label="Stage" v-model="editedRelease.production_stage"
                                      :items="productionStages" item-value="key" item-text="value"></v-select>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-file-input v-if="editedIndex == -1" v-model="editedRelease.release_files"
                                      show-size counter multiple directory mozdirectory webkitdirectory
                                      label="Ficheiros da release"></v-file-input>
                                    <v-col cols="12">
                                      <tiptap-vuetify v-model="editedRelease.release_notes" :extensions="extensions" />
                                      <v-col cols="12">
                                        <v-progress-linear v-if="isUploading" v-model="uploadProgress" reactive
                                          height="25">
                                          <strong>{{ uploadProgress }} %</strong>
                                        </v-progress-linear>
                                      </v-col>
                                    </v-col>
                                  </v-col>
                                </v-row>
                              </v-container>
                              <v-btn style="margin: auto;
                                  display: block;
                                  margin-left: auto;
                                  margin-right: auto;" v-if="editedIndex != -1" color="error" depressed
                                @click="deleteRelease(editedRelease)">
                                Eliminar Release
                              </v-btn>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" v-if="!isUploading" text @click="close">
                                Cancelar
                              </v-btn>
                              <v-btn color="primary" depressed text v-if="!isUploading" @click="saveRelease">
                                {{ formSaveButton }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.production_stage`]="{ item }">
                      <v-chip :color="getColor(item.production_stage)" dark>
                        {{ item.production_stage }}
                      </v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="editRelease(item)">
                            mdi-pencil
                          </v-icon>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <p class="pt-4">Sem dados para mostrar</p>
                      <v-btn class="mb-6" color="primary" @click="initialize">
                        Update
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-data-table height="68vh" fixed-header :headers="logHeaders" :items="logs" sort-by="id" sort-desc
              :loading="logsLoading" loading-text="A carregar dados..." :footer-props="{
                'items-per-page-options': [10, 25, 50]
              }" />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import release from '../store/release'
  import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
  } from "tiptap-vuetify";

  export default {
    data() {
      return {
        dialog: false,
        tab: null,
        isUploading: false,
        //uploadProgress: 0,
        tabNames: ["Releases", "Logs"],
        headers: [{
            text: "Id",
            value: "id",
            align: "center"
          },
          {
            text: "Produto",
            value: "application",
            align: "start"
          },
          {
            text: "Versão",
            value: "version",
            align: "start"
          },
          {
            text: "Stage",
            value: "production_stage",
            align: "center"
          },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            align: "center"
          }
        ],
        logHeaders: [{
            text: "Id",
            value: "id",
            align: "center"
          },
          {
            text: "Acção",
            value: "action",
            align: "start"
          },
          {
            text: "Resultado",
            value: "result",
            align: "start"
          },
          {
            text: "IP",
            value: "ip",
            align: "start"
          },
          {
            text: "User Agent",
            value: "user_agent",
            align: "start"
          },
          {
            text: "Utilizador",
            value: "username",
            align: "start"
          },
          {
            text: "Data",
            value: "created_at",
            align: "start"
          }
        ],
        octaReleases: [{
            key: "OctaGest",
            value: "Octa Gest"
          },
          {
            key: "OctaPos",
            value: "Octa Retail (POS)"
          },
          {
            key: "ApiMovel",
            value: "API Móvel"
          },
          {
            key: "ApiOcta",
            value: "API Octa"
          }
        ],
        productionStages: [{
            key: "production",
            value: "Produção"
          },
          {
            key: "staging",
            value: "Staging"
          },
          {
            key: "dev",
            value: "Development"
          }
        ],
        releasesLoading: false,
        logsLoading: true,
        releases: [],
        logs: [],
        editedIndex: -1,
        editedRelease: {
          id: 0,
          application: "OctaGest",
          version: "1.2101.01.0",
          release_notes: ``,
          production_stage: "dev",
          release_files: []
        },
        defaultRelease: {
          id: 0,
          application: "OctaGest",
          version: "1.2101.01.0",
          release_notes: ``,
          production_stage: "dev",
          release_files: []
        },
        currentReleaseRequest: {
          application: "OctaGest",
          production_stage: "dev"
        },
        currentOctaGestRelease: {
          version: "A carregar...",
          date: "...",
          stage: "..."
        },
        currentOctaPosRelease: {
          version: "A carregar...",
          date: "...",
          stage: "..."
        },
        //currentDevRelease: {
        //  version: "Inexistente",
        //  date: "Nunca"
        //},
        errors: [],
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [
            Heading,
            {
              options: {
                levels: [1, 2, 3]
              }
            }
          ],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak
        ]
      };
    },

    components: {
      TiptapVuetify
    },

    computed: {
      uploadProgress() {
        return release.state.uploadProgress
      },
      formTitle() {
        return this.editedIndex === -1 ? "Nova Release" : "Editar Release";
      },
      formSaveButton() {
        return this.editedIndex === -1 ? "Criar" : "Guardar";
      }
    },

    created() {
      this.initialize();
    },

    methods: {
      ...mapActions("admin", ["getLogs"]),
      ...mapActions("release", ["getReleases"]),
      ...mapActions("release", ["check"]),
      ...mapActions("release", ["store"]),
      ...mapActions("release", ["update"]),
      ...mapActions("release", ["delete"]),

      initialize() {
        this.fetchReleases();
        this.fetchLogs();

        // Data para preencher vários campos (release version)
        var currentTime = new Date();
        var year = currentTime
          .getFullYear()
          .toString()
          .substr(-2);
        var month = ("0" + (currentTime.getMonth() + 1).toString()).slice(-2);
        var day = ("0" + currentTime.getDate().toString()).slice(-2);

        var version = "1." + year + month + "." + day + ".0";

        var months = [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez"
        ];

        this.defaultRelease.version = version;
        this.defaultRelease.release_notes =
          `<b>[` +
          day +
          `-` +
          months[month - 1] +
          `-20` +
          year +
          `] Nova versão</b>`;
        this.editedRelease.version = version;
        this.editedRelease.release_notes =
          `<b>[` +
          day +
          `-` +
          months[month - 1] +
          `-20` +
          year +
          `] Nova versão</b>`;

        // Placeholder
        this.getApplicationReleases("OctaGest", "production");
        this.getApplicationReleases("OctaPos", "production");
      },
      fetchReleases() {
        this.releasesLoading = true;
        this.getReleases().then(response => {
          this.releasesLoading = false;
          this.releases = JSON.parse(JSON.stringify(response));
        });
      },
      fetchLogs() {
        this.getLogs().then(response => {
          this.logsLoading = false;
          this.logs = JSON.parse(JSON.stringify(response));
        });
      },
      getApplicationReleases(application, production_stage) {
        this.currentReleaseRequest.application = application;
        this.currentReleaseRequest.production_stage = production_stage;

        var params = {
          application: this.currentReleaseRequest.application,
          production_stage: this.currentReleaseRequest.production_stage
        };

        this.check(params)
          .then(response => {
              if (response != null) {
                // Dados obtidos
                if (application == "OctaGest") {
                  this.currentOctaGestRelease.version = response.version;
                  this.currentOctaGestRelease.date = response.created_at;
                  this.currentOctaGestRelease.stage = response.production_stage;
                }
                if (application == "OctaPos") {
                  this.currentOctaPosRelease.version = response.version;
                  this.currentOctaPosRelease.date = response.created_at;
                  this.currentOctaPosRelease.stage = response.production_stage;
                }
              } 
              else {
                if (application == "OctaGest") {
                  this.currentOctaGestRelease.version = "Inexistente";
                  this.currentOctaGestRelease.date = "Nunca";
                  this.currentOctaGestRelease.stage = "Nenhum";
                }
                if (application == "OctaPos") {
                  this.currentOctaPosRelease.version = "Inexistente";
                  this.currentOctaPosRelease.date = "Nunca";
                  this.currentOctaPosRelease.stage = "Nenhum";
                }
                this.$store.commit("setErrors", []);

                var errors = this.$store.state.errors;
                if (errors.length > 0) {
                  // Existem erros
                  this.errors = JSON.parse(JSON.stringify(errors));
                }
                this.$store.commit("setErrors", []);
              }
            })
          .catch(errors => {
            if (errors.length > 0) {
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
            }
          });
      },
          editRelease(item) {
            this.editedIndex = this.releases.indexOf(item);
            this.editedRelease = Object.assign({}, this.releases[this.editedIndex]);
            this.$nextTick(() => {
              if (this.editedIndex != -1) {}
              this.dialog = true;
            });
          },
          openDialog(application) {
            this.editedRelease.application = application;
            this.editedRelease.production_stage = "production";
            this.dialog = true;
          },
          saveRelease() {
            this.errors = {};
            let formData = new FormData();

            formData.append("id", this.editedRelease.id);
            formData.append("application", this.editedRelease.application);
            formData.append("version", this.editedRelease.version);
            formData.append("release_notes", this.editedRelease.release_notes);
            formData.append("production_stage", this.editedRelease.production_stage);

            if (this.editedIndex == -1) {
              // Nova release
              this.isUploading = true;
              // Adicionar ficheiros ao request
              for (var i = 0; i < this.editedRelease.release_files.length; i++) {
                let file = this.editedRelease.release_files[i];
                formData.append("release_files[" + i + "]", file);
                formData.append("file_dir[" + i + "]", file.webkitRelativePath);
              }
              // Iniciar request
              this.store(formData)
                .then(response => {
                  this.isUploading = false;
                  var errors = this.$store.state.errors;
                  if (errors.length === 0) {
                    // Adicionado com sucesso
                    this.close();
                    this.$nextTick(() => {
                      //this.showSnackbar("Release criada", "success")
                      this.fetchReleases();
                    });
                  } else {
                    // Existem erros
                    this.errors = JSON.parse(JSON.stringify(errors));
                  }
                  this.$store.commit("setErrors", []);
                })
                .catch(error => {
                  this.isUploading = false;
                  alert(error);
                });
            } else {
              // Release editada
              this.update(this.editedRelease)
                .then(response => {
                  var errors = this.$store.state.errors;
                  if (errors.length === 0) {
                    // Adicionado com sucesso
                    this.close();
                    this.$nextTick(() => {
                      //this.showSnackbar("Release editada", "success")
                      this.fetchReleases();
                    });
                  } else {
                    // Existem erros
                    this.errors = JSON.parse(JSON.stringify(errors));
                  }
                  this.$store.commit("setErrors", []);
                })
                .catch(error => {
                  alert(error);
                });
            }
          },
          deleteRelease(item) {
            this.errors = {};
            this.delete(this.editedRelease)
              .then(response => {
                var errors = this.$store.state.errors;
                if (errors.length === 0) {
                  // Eliminado com sucesso
                  this.close();
                  this.$nextTick(() => {
                    //this.showSnackbar("Release eliminada", "success")
                    this.fetchReleases();
                  });
                } else {
                  // Existem erros
                  this.errors = JSON.parse(JSON.stringify(errors));
                }
                this.$store.commit("setErrors", []);
              })
              .catch(error => {
                alert(error);
              });
          },
          close() {
            this.dialog = false;
            this.$nextTick(() => {
              // Reset em todas as opções
              this.editedRelease = Object.assign({}, this.defaultRelease);
              this.editedIndex = -1;
            });
          },
          getColor(stage) {
            if (stage === "dev") return "#F44336";
            else if (stage === "staging") return "#9C27B0";
            else return "#3F51B5";
          }
      }
    };
</script>