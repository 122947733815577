import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  getLogs(_, __) {
    return new Promise((resolve, reject) => {
      axios
        .get("/logs")
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
