import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";

import store from "./store";
import axios from "axios";

// Axios
axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

Vue.config.productionTip = false;

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response != null) {
      if (error.response.status === 422) {
        store.commit("setErrors", error.response.data.error);
      } else if (error.response.status === 401) {
        store.commit("auth/setUserData", null);
        localStorage.removeItem("authToken");
        router.push("/auth/signin/identifier");
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function(config) {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*.octacode.pt",
    "X-Request-Client": "octamanager"
  };

  return config;
});

// Plugins
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";
import MaterialDashboard from "./material-dashboard";
import Chartist from "chartist";

Vue.prototype.$Chartist = Chartist;

Vue.prototype.$octaProducts = [
  { id: 1, value: "Octa Gest" },
  //{ id: 2, value: "Octa Cloud" }
];
Vue.prototype.$octaModules = [
  { id: 8, value: "Faturação" },
  { id: 7, value: "Contabilidade" },
  { id: 12, value: "Recursos Humanos" },
  { id: 13, value: "Ativos" }
];
Vue.prototype.$octaModulesFaturacao = [
  { id: 0, value: "Compras" },
  { id: 1, value: "Encomendas" },
  { id: 2, value: "Avenças" },
  { id: 3, value: "Propostas" },
  { id: 4, value: "Consignações" },
  { id: 5, value: "Obras" },
  { id: 6, value: "CRM" },
  { id: 9, value: "Produtos Compostos" },
  { id: 10, value: "Bancos" },
  { id: 14, value: "Débitos Diretos" },
  { id: 15, value: "Frotas" },
  { id: 16, value: "Restauração (POS)" }
];
Vue.prototype.$octaModulesContabilidade = [
  { id: 11, value: "Gabinetes" }
];

Vue.use(MaterialDashboard);
Vue.use(GlobalDirectives);
Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  el: "#app",
  render: h => h(App),
  data: {
    Chartist: Chartist
  }
}).$mount("#app");
