<template>
  <v-card>
    <v-card-title>
      <span class="headline">Carregar documento</span>
      <v-spacer/>
      <v-icon @click="close">mdi-close</v-icon>
    </v-card-title>
    
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
            v-model="selectedDocTypeStr"
            :items="documentTypes"
            item-text="name"
            item-value="key"
            label="Tipo de documento"
            prepend-icon="mdi-file-document-outline"
            @change="docTypeChanged"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
            v-if="selectedDocType.anual == true"
            v-model="selectedYear"
            :items="years"
            label="Ano"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="fileList"
              counter
              multiple
              :label="fileLabel"
              show-size
              :accept="fileExtension"
              :rules="rules"
            ></v-file-input>
          </v-col>
          <v-progress-linear
            v-show="isUploading || isDone"
            :value="overallProgress"
            :color="progressColor"
          ></v-progress-linear>

          <v-col cols="12" v-if="filesFailed.length > 0">
            <span class="text-subtitle-1">O carregamento falhou para os seguintes ficheiros:</span>
            <v-list>
              <v-list-item v-for="(item, index) in filesFailed" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}: {{ item.error }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="close">
        Fechar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isUploading || isUploadDisabled"
        color="primary"
        depressed
        text
        @click="uploadFiles">
        Carregar
      </v-btn>
  </v-card-actions>
  <v-snackbar
    v-model="snackbar"
    :timeout="3000"
    bottom
    right
    :color="snackbarStatus">
      {{ snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        OK
      </v-btn>
    </template>
  </v-snackbar>
</v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';

function initialState() {
  return {
    accountantData: null,
    documentTypes: [
      { name: "Geral", key: "geraldocs", anual: false },
      { name: "Dossier Fiscal", key: "geraldossierfiscal", anual: false },
      { name: "Contabilidade Anual", key: "docscontabilidade", anual: true },
      { name: "Dossier Anual", key: "dossierfiscal", anual: true },
      { name: "SAF-T", key: "saft", anual: false },
    ],
    years: [],
    rules: [
      files =>
      !files ||
      !files.some(file => file.size > 5e6) ||
      "Tamanho máximo é de 5 MB (por ficheiro)"
    ],
    
    selectedDocTypeStr: "geraldocs",
    selectedDocType: { name: "Geral", key: "geraldocs", anual: false },
    selectedYear: new Date().getFullYear(),
    fileList: [],
    
    isUploading: false,
    isDone: false,
    totalFiles: 0,
    totalFilesCompleted: 0,
    progressValue: 0,
    overallProgress: 0,

    filesSuccess: [],
    filesFailed: [],
    
    errors: [],
    snackbar: false,
    snackbarText: "",
    snackbarStatus: "success",
    progressColor: "primary",
    fileLabel: "Ficheiros selecionados (PDF, JPG ou PNG)",
    fileExtension: ".pdf,.jpg,.jpeg,.png", // .PDF, .JPG, .PNG (docs) ; .XML (SAFT)
    allowedFileExtensions: ["pdf", "jpg", "jpeg", "png", "xml"]
  };
}

export default {
  name: "document-upload-card",
  props: ["license_code", "company", "client_id"],

  data: function() {
    return initialState();
  },

  watch: {
    totalFilesCompleted: function() {
      if (this.totalFilesCompleted >= this.totalFiles && this.totalFiles > 0) {
        this.clearSelectedFiles();
      }
    }
  },

  mounted() {
    this.generateYears();
    if (this.user.data.role == 'client') {
      this.documentTypes.splice(1, 4); // remove 4 elements, starting from 1
    }
  },

  computed: {
    ...mapGetters("auth", { user: "user" }),
    ...mapGetters("documentstorage", { selectedLicense: "selectedLicense" }),

    isUploadDisabled() {
      return (
        !this.fileList ||
        this.fileList.length == 0 ||
        this.fileList.some((file) => file.size > 5e6)
      );
    },
  },
  
  methods: {
    ...mapActions("documentstorage", ["generateS3Upload", "notify"]),
    
    clearSelectedFiles() {
      this.isDone = true;
      this.isUploading = false;
      this.progressColor = "success";
      this.triggerEmailNotification();
      this.totalFiles = 0;
      this.totalFilesCompleted = 0;
      this.fileList = [];
    },

    close() {
      Object.assign(this.$data, initialState());
      this.$emit("dialogUploadClosed", 0);
    },

    async uploadFiles() {
      this.filesSuccess = [];
      this.filesFailed = [],
      this.isDone = false;
      this.isUploading = true;
      this.progressColor = "primary";
      this.totalFiles = this.fileList.length;
      this.totalFilesCompleted = 0;
      this.overallProgress = 0;
      
      if (this.fileList == [] || this.fileList == null) {
        return;
      }
      
      const fileProgress = new Array(this.fileList.length).fill(0);
      for (var i = 0; i < this.fileList.length; i++) {
        let file = this.fileList[i];

        let fileExtension = this.getFileExtension(file);

        if (!this.allowedFileExtensions.includes(fileExtension)) {
          this.totalFilesCompleted++;
          this.filesFailed.push(
            {
              name: file.name,
              error: "Extensão inválida"
            }
          );
          continue;
        }

        if (fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png") {
          file = await this.convertToPDF(file, fileExtension);
        }
        var params = this.generateS3RequestParams(file);
        
        await this.generateS3Upload(params)
          .then(res => {
              var result = JSON.parse(JSON.stringify(res));
              return result.data.attributes;
            }
          )
          .then(async (uploadUrl) => {
            if(uploadUrl != null){
              // Upload para o URL AWS recebido
              await axios.put(uploadUrl, file, {
                headers: {
                  'Content-Type': file.type,
                },
                onUploadProgress: (progressEvent) => {
                  // Calculate the overall progress based on the completion of each file
                  fileProgress[i] = (progressEvent.loaded / progressEvent.total) * 100;
                  this.calculateOverallProgress(fileProgress);
                },
                transformRequest: [(data, headers) => {
                  // Delete the Authorization header
                  delete headers['Authorization'];
                  return data;
                }],
              })
              .then(_ => {
                this.progressValue = (this.totalFilesCompleted * 100) / this.totalFiles;
                this.totalFilesCompleted++;
                this.filesSuccess.push(file.name);
              })
              .catch(uploadError => {
                this.progressValue = (this.totalFilesCompleted * 100) / this.totalFiles;
                this.totalFilesCompleted++;
                this.filesFailed.push(
                  {
                    name: file.name,
                    error: uploadError
                  }
                );
              });
            }
          });
      }
    },

    triggerEmailNotification() {
      if (this.isDone && this.user.data.role == 'client') {
        var params = {
          file_total: this.filesSuccess.length,
          files: this.filesSuccess,
          is_new_files: true,
        }
        this.notify(params);
      }
    },

    // async uploadToS3(url, file, fileProgress, i) {
    //   await axios.put(url, file, {
    //     headers: {
    //       'Content-Type': file.type,
    //     },
    //     onUploadProgress: (progressEvent) => {
    //       // Calculate the overall progress based on the completion of each file
    //       fileProgress[i] = (progressEvent.loaded / progressEvent.total) * 100;
    //       this.overallProgress = this.calculateOverallProgress(fileProgress);
    //     },
    //     transformRequest: [(data, headers) => {
    //       // Delete the Authorization header
    //       delete headers['Authorization'];
    //       return data;
    //     }],
    //   })
    //   .then(_ => {
    //     this.progressValue = (this.totalFilesCompleted * 100) / this.totalFiles;
    //     this.totalFilesCompleted++;
    //     this.filesSuccess.push(file.name);
    //   })
    //   .catch(uploadError => {
    //     this.progressValue = (this.totalFilesCompleted * 100) / this.totalFiles;
    //     this.totalFilesCompleted++;
    //     this.filesFailed.push(
    //       {
    //         name: file.name,
    //         error: uploadError
    //       }
    //     );
    //   });
    // },

    calculateOverallProgress(progress) {
      this.overallProgress = Math.round((progress.reduce((sum, value) => sum + value, 0) / progress.length) || 0);
    },

    generateS3RequestParams(file) {
      var params = {
        client_id: this.client_id,
        license_code: this.license_code,
        company_name: this.company,
        document_type: this.selectedDocType.key,
        document_name: file.name
      };
      
      if (this.selectedLicense != undefined && this.selectedLicense != "") {
        params["license_code"] = this.selectedLicense;
      }
      if (this.selectedDocType.anual) {
        params["year"] = this.selectedYear;
      }
      
      return params;
    },
    docTypeChanged() {
      this.selectedDocType = this.documentTypes.find(obj => {
        this.fileList = [];
        
        if(obj.key != "saft") {
          this.fileLabel = "Ficheiros selecionados (PDF, JPG ou PNG)";
          this.fileExtension = ".pdf,.jpg,.png";
        }
        else {
          this.fileLabel = "Ficheiros selecionados (XML)";
          this.fileExtension = ".xml";
        }
        
        return obj.key === this.selectedDocTypeStr;
      });
    },
    async convertToPDF(file, extension) {
      try {
        const imageBytes = await this.readFile(file);
        const pdfDoc = await PDFDocument.create();

        const page = pdfDoc.addPage();
        var image;
        if (extension == "jpg" || extension == "jpeg" ) {
          image = await pdfDoc.embedJpg(imageBytes);
        } else {
          image = await pdfDoc.embedPng(imageBytes);
        }

        page.drawImage(image, {
          x: 0,
          y: 0,
          width: page.getWidth(),
          height: page.getHeight()
        });

        const pdfBytes = await pdfDoc.save();

        var fileName = this.renameFileExtension(file.name);
        return this.convertBase64ToPDFFile(pdfBytes, fileName)
      } catch(ex) {
        if (extension == "png") {
          return await this.convertToPDF(file, "jpg");
        } else {
          return file;
        }
      }
    },
    async convertBase64ToPDFFile(byteArray, fileName) {
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const file = new File([blob], fileName, { type: 'application/pdf' });
      return file;
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(new Uint8Array(reader.result));
        reader.onerror = (error) => reject(error);

        reader.readAsArrayBuffer(file);
      });
    },
    getFileExtension(file) {
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      if (lastDotIndex === -1) {
        return '';
      }
      return fileName.slice(lastDotIndex + 1).toLowerCase();
    },
    renameFileExtension(fileName) {
      const lastDotIndex = fileName.lastIndexOf('.');
      if (lastDotIndex !== -1) {
        fileName = fileName.slice(0, lastDotIndex);
      }
      return fileName + '.pdf';
    },
    generateYears() {
      for (let year = this.selectedYear; year >= 2021; year--) {
        this.years.push(year.toString());
      }
      this.selectedYear = this.years[0];
    },
  }
};
</script>
