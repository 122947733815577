import Vue from "vue";
import Router from "vue-router";

import Auth from "@/pages/auth/Auth";
import Signin from "@/pages/auth/Signin";
import SigninIdentifier from "@/pages/auth/SigninIdentifier";
import SigninPassword from "@/pages/auth/SigninPassword";
import Activate from "@/pages/auth/Activate.vue";
import Reset from "@/pages/auth/Reset.vue";
import ForgotPassword from "@/pages/auth/ForgotPassword.vue";

import DashboardLayout from "@/layouts/DashboardLayout.vue";
import Dashboard from "@/pages/Dashboard.vue";
import AdminPanel from "@/pages/AdminPanel.vue";
import UserProfile from "@/pages/UserProfile.vue";
import LicensesList from "@/pages/LicensesList.vue";
import ClientLicenses from "@/pages/ClientLicenses.vue";
import DocumentBrowser from "@/components/DocumentBrowser.vue";
import Clients from "@/pages/Clients.vue";
import Partners from "@/pages/Partners.vue";
import Accountants from "@/pages/Accountants.vue";
import Notifications from "@/pages/Notifications.vue";
import Tickets from "@/pages/Tickets.vue";

// Client
import ClientAccountant from "@/pages/client/ClientAccountant.vue";

// Accountant
import AccountantClientList from "@/pages/accountant/AccountantClientList.vue";
import AccountantDocumentBrowser from "@/pages/accountant/AccountantDocumentBrowser.vue";

Vue.use(Router);

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/dashboard");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/auth");
  }
};

export default new Router({
  linkExactActiveClass: "nav-item active",
  routes: [
    {
      path: "/auth",
      name: "auth",
      beforeEnter: guest,
      component: Auth,
      redirect: "/auth/signin/identifier",
      children: [
        {
          path: "signin",
          component: Signin,
          redirect: "/auth/signin/identifier",
          children: [
            {
              path: "identifier",
              name: "signin",
              component: SigninIdentifier
            },
            {
              path: "password",
              name: "password",
              component: SigninPassword
            },
            {
              path: "/activate/:resetToken",
              name: "activate",
              beforeEnter: guest,
              component: Activate,
              props: {
                header: { colorOnScroll: 400 }
              }
            },
            {
              path: "/forgot_password",
              name: "forgotpassword",
              beforeEnter: guest,
              component: ForgotPassword,
              props: {
                header: { colorOnScroll: 400 }
              }
            },
            {
              path: "/reset/:resetToken",
              name: "reset",
              beforeEnter: guest,
              component: Reset,
              props: {
                header: { colorOnScroll: 400 }
              }
            }
          ]
        }
      ]
    },
    {
      path: "/client",
      component: DashboardLayout,
      redirect: "/dashboard",
      beforeEnter: auth,
      children: [
        {
          path: "accountant",
          name: "Contabilista",
          component: ClientAccountant
        }
      ]
    },
    {
      path: "/accountant",
      component: DashboardLayout,
      redirect: "/dashboard",
      beforeEnter: auth,
      children: [
        {
          path: "clients",
          name: "Clientes",
          component: AccountantClientList
        },
        {
          path: "documents/:client_id",
          name: "Documentos de cliente",
          component: AccountantDocumentBrowser
        }
      ]
    },
    {
      path: "/",
      component: DashboardLayout,
      redirect: "/dashboard",
      beforeEnter: auth,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard
        },
        {
          path: "admin",
          name: "Administração",
          component: AdminPanel
        },
        {
          path: "user",
          name: "Utilizador",
          component: UserProfile
        },
        {
          path: "licensing",
          name: "Gestor Licenças",
          component: LicensesList
        },
        {
          path: "licenses",
          name: "Licenças",
          component: ClientLicenses
        },
        {
          path: "clients",
          name: "Lista de Clientes",
          component: Clients
        },
        {
          path: "partners",
          name: "Parceiros",
          component: Partners
        },
        {
          path: "accountants",
          name: "Contabilistas",
          component: Accountants
        },
        {
          path: "tickets",
          name: "Tickets",
          component: Tickets
        },
        {
          path: "notifications",
          name: "Notifications",
          component: Notifications
        },
        {
          path: "documents",
          name: "Documentos armazenados",
          component: DocumentBrowser
        }
      ]
    }
  ]
});
