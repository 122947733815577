<template>
  <div>    
    <v-dialog
    v-model="dialogDeleteDocument"
    width="auto"
    >
    <v-card>
      <v-card-title>
        Pretende eliminar este documento?
      </v-card-title>
      <v-card-text>
        Esta acção não poderá ser desfeita.
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="dialogDeleteDocument = false">Cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deleteDocument()">Eliminar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
  <v-card>
    <v-card-title>
      <span class="headline">Alterar documento</span>
    </v-card-title>    
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
            v-model="selectedTab"
            :items="documentTypes"
            item-text="name"
            item-value="key"
            label="Tipo de documento"
            prepend-icon="mdi-file-document-outline"
            @change="docTypeChanged"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
            v-if="selectedDocType.anual == true"
            v-model="selectedYear"
            :items="yearsList"
            label="Ano"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
            v-model="editedDocument.name"
            label="Nome documento"
            prepend-icon="mdi-text-box-edit"
            :disabled="true"
            ></v-text-field>
          </v-col>
          
          <v-progress-linear
          v-show="isMoving"
          :value="uploadProgress"
          ></v-progress-linear>
        </v-row>
      </v-container>
    </v-card-text>
    
    <v-card-actions>
      <v-btn 
        color="error" 
        @click="dialogDeleteDocument = true"
      >
        Eliminar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
      <v-btn
      :disabled="isMoving"
      color="primary"
      depressed
      text
      @click="moveFile"
      >
      Guardar
    </v-btn>
  </v-card-actions>
  <v-snackbar
  v-model="snackbar"
  :timeout="3000"
  bottom
  right
  :color="snackbarStatus"
  >
  {{ snackbarText }}
  <template v-slot:action="{ attrs }">
    <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
  </template>
</v-snackbar>
</v-card>
</div>
</template>
<script>
import { mapActions } from "vuex";

function initialState() {
  return {
    documentTypes: [
    { name: "Geral", key: "geraldocs", anual: false },
    { name: "Dossier Fiscal", key: "geraldossierfiscal", anual: false },
    { name: "Contabilidade Anual", key: "docscontabilidade", anual: true },
    { name: "Dossier Anual", key: "dossierfiscal", anual: true },
    { name: "SAF-T", key: "saft", anual: false },
    ],
    yearsList: ["2021", "2022"],
    rules: [
    (files) =>
    !files ||
    !files.some((file) => file.size > 5e6) ||
    "Tamanho máximo é de 5 MB (por ficheiro )",
    ],
    
    selectedDocType: {
      name: "Geral",
      key: "geraldocs",
      anual: false,
    },
    selectedTab: "geraldocs",
    selectedYear: "2022",
    
    originalDocType: {
      name: "Geral",
      key: "geraldocs",
      anual: false,
    },
    originalYear: "",
    
    originalDocument: { name: "", date: "", size: "" },
    editedDocument: { name: "", date: "", size: "" },
    
    isMoving: false,
    uploadProgress: 0,
    uploadTotalFiles: 0,
    errors: [],
    snackbar: false,
    snackbarText: "",
    snackbarStatus: "success",
    dialogDeleteDocument: false,
  };
}

export default {
  name: "document-edit-card",
  props: ["clientId", "document", "tab", "company", "user"],
  data: function () {
    return initialState();
  },
  watch: {
    uploadProgress: function () {
      if (this.uploadProgress >= 100) {
        this.close();
      }
    },
    document: function(newdocument) {
      this.initialize();
    }
  },
  mounted() {
    if (this.user.role == 'client') {
      this.documentTypes.splice(1, 4); // remove 4 elements, starting from 1
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("documentstorage", [ "move", "delete" ]),
    initialize() {
      this.originalDocument = JSON.parse(this.document);
      this.editedDocument = JSON.parse(JSON.stringify(this.originalDocument));
      this.editedDocument.name = this.originalDocument.name.replace(".pdf", "");
      
      // Lista anos
      this.setYearsList();
      
      // Tipo doc a editar
      this.selectedTab = this.tab;
      this.docTypeChanged();
      
      // Valores originais
      this.originalDocType = this.selectedDocType;
      this.originalYear = this.editedDocument.year;
    },
    close() {
      Object.assign(this.$data, initialState());
      this.$emit("dialogEditClosed", 0);
    },
    setYearsList() {
      var currentYear = new Date().getFullYear();
      this.yearsList = [];
      for (var i = currentYear; i >= 2021; i--) {
        this.yearsList.push(i);
      }
      this.selectedYear = this.yearsList[0];
    },
    moveFile() {
      var params = {};
      this.editedDocument.name += ".pdf";
      
      params["client_id"] = this.clientId;
      params["file_key"] = this.originalDocument.name;
      
      if(this.originalYear != "" && this.originalYear != undefined){
        params["dir_source"] = "/" + this.company + "/" + this.originalYear + "/" + this.originalDocType.key;
      }else{
        params["dir_source"] = "/" + this.company + "/" + this.originalDocType.key;
      }
      if(this.selectedDocType.anual == true){
        params["dir_target"] = "/" + this.company + "/" + this.selectedYear + "/" + this.selectedDocType.key;
      }else{
        params["dir_target"] = "/" + this.company + "/" + this.selectedDocType.key;
      }
      
      // Request move
      this.move(params)
      .then(res => JSON.parse(JSON.stringify(res)))
      .then(data => {
        if(data.status == 200){
          this.close();
        }else{
          // TODO Mostrar erro
        }
      });
    },
    deleteDocument() {
      var params = {};
      this.editedDocument.name += ".pdf";

      params["client_id"] = this.clientId;

      var dir = "";
      if(this.originalYear != "" && this.originalYear != undefined){
        dir = "/" + this.company + "/" + this.originalYear + "/" + this.originalDocType.key;
      }else{
        dir = "/" + this.company + "/" + this.originalDocType.key;
      }
      params["file_keys"] = dir + "/" + this.originalDocument.name;

      // Request delete
      this.delete(params)
        .then(res => JSON.parse(JSON.stringify(res)))
        .then(res => {
          if (res == "success") {
            this.close();
          }else{
            // TODO Mostrar erro
          }
        });
    },
    docTypeChanged() {
      this.selectedDocType = this.documentTypes.find((obj) => {
        return obj.key === this.selectedTab;
      });
    },
  },
};
</script>
