<template>
  <div>
    <div class="text-center w-100">
      <h1 class="text-h5 mb-2">
        Introduza a sua password
      </h1>
      <v-chip
        class="mb-10"
        outlined
        link
        @click="$router.push({ name: 'signin' })"
      >
        <v-avatar left>
          <v-icon color="secondary">
            mdi-account-circle
          </v-icon>
        </v-avatar>
        {{ identifier }}
        <v-avatar right>
          <v-icon color="secondary">
            mdi-chevron-down
          </v-icon>
        </v-avatar>
      </v-chip>

      <v-form @submit.prevent="tryLogin">
        <v-text-field
          class="mb-10"
          :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          label="Password"
          name="password"
          v-model="password"
          :type="show ? 'input' : 'password'"
          hide-details="auto"
          outlined
          :error-messages="error"
          @click:append="show = !show"
        />
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-form>

      <div class="d-flex justify-space-between">
        <v-btn
          class="text-none letter-spacing-0 font-weight-bold"
          style="margin-left: -16px;"
          color="primary"
          text
          @click="forgotPassword"
        >
          Esqueci-me da minha password
        </v-btn>
        <v-btn
          class="text-none"
          style="min-width: 88px;"
          color="primary"
          depressed
          @click="tryLogin"
        >
          Seguinte
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    error: "",
    show: false,
    overlay: false
  }),
  computed: {
    identifier: {
      get() {
        return this.$store.state.auth.identifier;
      }
    },
    password: {
      get() {
        return this.$store.state.auth.password;
      },
      set(value) {
        this.$store.commit("auth/updatePassword", value);
      }
    }
  },
  methods: {
    ...mapActions("auth", ["login"]),

    tryLogin: function() {
      this.overlay = true;
      var loginUser = {
        email: this.identifier,
        password: this.password
      };

      this.login(loginUser)
        .then(() => {
          this.$router.push("/");
        })
        .catch(error => {
          this.overlay = false;
          this.error = error.response.data.error;
        });
    },
    forgotPassword: function() {
      this.$router.push("/forgot_password");
    }
  }
};
</script>
