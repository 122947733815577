import axios from "axios";

const state = {
  uploadProgress: 0,
};
const getters = {
  getUploadProgress: state => state.uploadProgress
};
const mutations = {
  setUploadProgress(state, progress) {
    state.uploadProgress = progress;
  },
};

const actions = {
  getReleases(_, __) {
    return new Promise((resolve, reject) => {
      axios
        .get("/release")
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  check(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/release/check", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => reject(error));
    });
  },
  store(_, formData) {
    state.uploadProgress = 0;
    let config = {
      onUploadProgress(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        state.uploadProgress = percentCompleted;
        return percentCompleted;
      },
    };
    return axios.post("/release/create", formData, config).
      then(x => x.request.response).
      catch(error => error);
  },
  update(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/release/update", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/release/delete", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
