import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center w-100"},[_c('h1',{staticClass:"text-h5 mb-2"},[_vm._v(" Introduza a sua password ")]),_c(VChip,{staticClass:"mb-10",attrs:{"outlined":"","link":""},on:{"click":function($event){return _vm.$router.push({ name: 'signin' })}}},[_c(VAvatar,{attrs:{"left":""}},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v(" mdi-account-circle ")])],1),_vm._v(" "+_vm._s(_vm.identifier)+" "),_c(VAvatar,{attrs:{"right":""}},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v(" mdi-chevron-down ")])],1)],1),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.tryLogin.apply(null, arguments)}}},[_c(VTextField,{staticClass:"mb-10",attrs:{"append-icon":_vm.show ? 'mdi-eye-off-outline' : 'mdi-eye-outline',"label":"Password","name":"password","type":_vm.show ? 'input' : 'password',"hide-details":"auto","outlined":"","error-messages":_vm.error},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VOverlay,{attrs:{"value":_vm.overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c(VBtn,{staticClass:"text-none letter-spacing-0 font-weight-bold",staticStyle:{"margin-left":"-16px"},attrs:{"color":"primary","text":""},on:{"click":_vm.forgotPassword}},[_vm._v(" Esqueci-me da minha password ")]),_c(VBtn,{staticClass:"text-none",staticStyle:{"min-width":"88px"},attrs:{"color":"primary","depressed":""},on:{"click":_vm.tryLogin}},[_vm._v(" Seguinte ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }