import axios from "axios";

const state = {
  clientData: {
    id: 0,
    creator_id: 0,
    name: "",
    email: "",
    company: "",
    role: "client",
  },
  clientIndexData: -1
};

const getters = {
  getClientData: state => state.clientData,
  getClientIndex: state => state.clientIndexData
};

const mutations = {
  setClientData(state, payload) {
    state.clientData = payload;
  },
  setClientIndex(state, payload) {
    state.clientIndexData = payload;
  }
};

const actions = {
  store(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/client/store", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  update(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/client/update", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getOwnedClients(_, __) {
    return new Promise((resolve, reject) => {
      axios
        .get("/client/own")
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  accountant({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/client/accountant", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteUserRequest(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/client/disable", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  enableUserRequest(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/client/enable", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
