import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(this.accountantData === null)?_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"544","outlined":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"text-overline mb-4"},[_vm._v("Contabilista")]),_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_vm._v(" Por atribuir ")]),_c(VListItemSubtitle,[_vm._v(" Fale com o seu Contabilista Octa Code para conseguir consultar e transmitir documentos ")])],1),_c(VListItemAvatar,{attrs:{"tile":"","size":"80","color":"grey"}})],1),_c(VCardActions,[_c(VSpacer)],1)],1):_c('div',[_c('document-browser',{attrs:{"client_id":this.accountantData.client_id,"accountant_action":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }