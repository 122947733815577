<template>
  <div>
    <div class="text-center">
      <h1 class="headline mb-2">
        Ativar nova conta
      </h1>
      <span class="d-inline-block mb-8">Crie uma nova palavra passe</span>
    </div>

    <v-form v-model="passwordsValid" ref="activationForm">
      <v-text-field prepend-icon="person" v-model="user.name" name="name" label="Nome do responsável" type="text"
        outlined>
      </v-text-field>
      <v-text-field prepend-icon="mdi-at" v-model="user.email" name="email" label="Email" type="text" outlined disabled>
      </v-text-field>
      <v-text-field class="mb-4" prepend-icon="lock" hint="Password válida"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" label="Password" name="password" v-model="user.password"
        :type="show ? 'text' : 'password'" outlined @click:append="() => (show = !show)" :rules="[rules.password]" />

      <v-text-field class="mb-4" prepend-icon="lock" hint="Password corresponde"
        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'" label="Confirmar Password"
        v-model="user.password_confirm" name="password_confirm" :type="showConfirm ? 'text' : 'password'" outlined
        @click:append="() => (showConfirm = !showConfirm)" :rules="[rules.matching]"></v-text-field>
    </v-form>

    <v-alert prominent type="error" dense v-if="error">
      {{ this.error }}
    </v-alert>

    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn class="text-none" style="min-width: 88px;" color="primary" depressed @click="activateAccount"
        :disabled="!passwordsValid">
        Confirmar
      </v-btn>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  import {
    mapActions
  } from "vuex";
  export default {
    data() {
      return {
        error: null,
        show: false,
        showConfirm: false,
        overlay: false,
        user: {
          name: "",
          email: "",
          company: "",
          password: "",
          password_confirm: "",
          reset_token: ""
        },
        passwordsValid: false,
        passwordPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        rules: {
          password: value => {
            return (
              this.passwordPattern.test(value) ||
              "Min. 8 caracteres com pelo menos uma letra maiúscula, minúscula e um número."
            );
          },
          matching: value => {
            return (
              (value == this.user.password && value != "") ||
              "As passwords inseridas não coincidem"
            );
          }
        }
      };
    },
    mounted() {
      this.fetchResetData();
    },
    methods: {
      ...mapActions("auth", ["login"]),
      ...mapActions("auth", ["getResetData"]),
      ...mapActions("auth", ["activate"]),

      fetchResetData() {
        this.user.reset_token = this.$route.params["resetToken"];
        var params = {
          reset_token: this.user.reset_token
        };
        this.getResetData(params)
          .then(response => {
            if (response.data.attributes != null) {
              // Token existe, parse nos dados
              var json = response.data.attributes;
              if (json.id != null) {
                this.user.name = json.name;
                this.user.email = json.email;
                this.user.company = json.company;
                this.overlay = false;
                return;
              }
            } else {
              var errors = this.$store.state.errors;
              if (errors.length > 0) {
                this.error = errors;
              }
            }

            // Se chegar aqui, token não existe, redirecionar para a página principal
            this.$store.commit("setErrors", []);
            this.$router.push("/");
          })
          .catch(() => {
            // Dados inválidos, push para a página inicial
            this.$router.push("/");
          });
      },

      activateAccount() {
        this.error = null;
        this.overlay = true;

        this.activate(this.user).then(response => {
          if (response.data.attributes != null) {
            // Password criada com sucesso
            var loginUser = {
              email: this.user.email,
              password: this.user.password
            };
            this.login(loginUser).then(() => {
              this.$router.push("/");
            });

            this.user.password = "";
            this.user.password_confirm = "";
          } else {
            this.overlay = false;
            var errors = this.$store.state.errors;
            if (errors.length > 0) {
              // Existem erros
              this.error = errors;
            }
          }
          this.$store.commit("setErrors", []);
        });
      }
    }
  };
</script>