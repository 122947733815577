<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-66">
        <edit-profile-form data-background-color="indigo"> </edit-profile-form>
      </div>
    </div>
  </div>
</template>

<script>
import UserCard from "../pages/UserProfile/UserCard.vue";
import EditProfileForm from "../pages/UserProfile/EditProfileForm.vue";

export default {
  components: {
    EditProfileForm
  }
};
</script>
