import axios from "axios";

const state = {};
const getters = {};
const mutations = {};

const actions = {
  getAccountants(_, __) {
    return new Promise((resolve, reject) => {
      axios
        .get("/accountant")
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  store(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/accountant/store", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  update(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/accountant/update", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
