<template>
  <div class="container">
    <v-row dense>
        <v-col cols="4">
          <v-card
            color="#1D2953"
            dark
          >
            <v-card-title class="text-h5">
              Licenças e documentos
            </v-card-title>

            <v-card-subtitle>Controle as suas licenças, consulte documentos e seguranças associados</v-card-subtitle>

            <v-card-actions>
              <v-btn text to="/licenses">
                Ver agora
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            color="#1D2953"
            dark
          >
            <v-card-title class="text-h5">
              Perfil utilizador
            </v-card-title>

            <v-card-subtitle>Mantenha o seu perfil atualizado para facilitar interações</v-card-subtitle>

            <v-card-actions>
              <v-btn text to="/user">
                Ver perfil
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {

    };
  },
  computed : {
    ...mapGetters("auth", { user: "user" }),
  },
  methods: {

  }
};
</script>