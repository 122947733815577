import axios from "axios";

const state = {};
const getters = {};
const mutations = {};

const actions = {
  create(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/licenses/store", payload)
        .then(response => resolve(response))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  update(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/licenses/update", payload)
        .then(response => resolve(response))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  getOwnedLicenses() {
    return new Promise((resolve, reject) => {
      axios
        .get("/licenses/")
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  generateKey(_, __) {
    return new Promise((resolve, reject) => {
      axios
        .get("/licenses/genlicense")
        .then(response => resolve(response))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  debugPasswords(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/licenses/dbdebug", { params })
        .then(response => resolve(response))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
