<template>
  <v-container fluid fill-height>
    <v-layout style="height: 80vh">
      <v-flex style="overflow: auto">
        <v-data-table :headers="headers" :items="accountantList" sort-by="id" :loading="accountantListLoading"
          loading-text="A carregar dados..." :footer-props="{
            'items-per-page-options': [10, 25, 50]
          }">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <!--
              <v-dialog v-model="dialog" max-width="700px" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    Novo Contabilista
                  </v-btn>
                </template>

                <v-card :loading="cardLoading">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editedAccountant.email" :error-messages="errors['email']" label="Email"
                            v-bind:disabled="editedIndex != -1">
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="editedAccountant.name" :error-messages="errors['name']"
                            label="Nome do responsável">
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="editedAccountant.company" :error-messages="errors['company']"
                            label="Empresa">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="justify-center" v-if="editedIndex != -1 && user.data.role == 'admin'">
                    <v-btn color="darken-1" @click="resendActivationButton">
                      Reenviar ativação
                    </v-btn>
                    <v-btn color="darken-1" @click="resetPassword">
                      Reset Password
                    </v-btn>
                  </v-card-actions>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-bind:disabled="cardLoading" color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn v-bind:disabled="cardLoading" color="primary" depressed text @click="saveAccountant">
                      {{ formSaveButton }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              -->
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!--
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="editAccountant(item)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="associateClients(item)">
                  mdi-account-multiple-plus
                </v-icon>
              </template>
              <span>Clientes</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <p class="pt-4">Sem dados para mostrar</p>
            <v-btn class="mb-6" color="primary" @click="initialize">
              Update
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";

  export default {
    data: () => ({
      dialog: false,
      accountantListLoading: true,
      headers: [{
          text: "Id",
          value: "id",
          align: "center"
        },
        {
          text: "Nome",
          value: "name",
          align: "start"
        },
        {
          text: "Empresa",
          value: "company",
          align: "start"
        },
        {
          text: "Email",
          value: "email",
          align: "center"
        },
        {
          text: "Criador",
          value: "creator.name",
          align: "center"
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ],
      accountantList: [],
      editedIndex: -1,
      editedAccountant: {
        id: 0,
        creator_id: 0,
        name: "",
        email: "",
        company: ""
      },
      defaultAccountant: {
        id: 0,
        creator_id: 0,
        name: "",
        email: "",
        company: ""
      },
      cardLoading: false,
      errors: []
    }),

    computed: {
      ...mapGetters("auth", {
        user: "user"
      }),
      formTitle() {
        return this.editedIndex === -1 ? "Novo Contabilista" : "Editar Contabilista";
      },
      formSaveButton() {
        return this.editedIndex === -1 ? "Criar" : "Guardar";
      }
    },

    created() {
      this.initialize();
    },

    methods: {
      ...mapActions("accountant", ["store"]),
      ...mapActions("accountant", ["update"]),
      ...mapActions("accountant", ["getAccountants"]),
      ...mapActions("auth", ["passwordReset"]),
      ...mapActions("auth", ["resendActivation"]),

      fetchAccountants() {
        this.getAccountants().then(response => {
          this.accountantList = JSON.parse(JSON.stringify(response));
          this.accountantListLoading = false;
        });
      },
      initialize() {
        this.fetchAccountants();
      },
      saveAccountant() {
        this.cardLoading = true;
        this.errors = {};
        if (this.editedIndex == -1) {
          // Novo Contabilista
          this.store(this.editedAccountant).then(response => {
              this.cardLoading = false;
              var errors = this.$store.state.errors;
              if (errors.length === 0) {
                // Adicionado com sucesso
                this.onSuccess(response.data.attributes.id);
              } else {
                // Existem erros
                this.errors = JSON.parse(JSON.stringify(errors));
              }
              this.$store.commit("setErrors", []);
            })
            .catch(error => {
              this.cardLoading = false;
              alert(error);
            });
        } else {
          // Contabilista a editar
          this.update(this.editedAccountant).then(response => {
            this.cardLoading = false;
            var errors = this.$store.state.errors;
            if (errors.length === 0) {
              // Adicionado com sucesso
              this.onSuccess(-1);
            } else {
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
            }
            this.$store.commit("setErrors", []);
          }).catch(error => {
            this.cardLoading = false;
            alert(error);
          });
        }
      },
      editAccountant(item) {
        this.editedIndex = this.accountantList.indexOf(item);
        this.editedAccountant = Object.assign({},
          this.accountantList[this.editedIndex]
        );

        this.$nextTick(() => {
          if (this.editedIndex != -1) {
            this.editedAccountant.creator_id = parseInt(
              this.editedAccountant.creator_id
            );
          }
          this.dialog = true;
        });
      },
      associateClients(item) {
        var selectedAccountant = this.accountantList[this.accountantList.indexOf(item)];

      },
      resendActivationButton() {
        var payload = {
          user_id: this.editedAccountant.id
        };

        this.resendActivation(payload)
          .then(response => {
            // TODO
          })
          .catch(err => {});
      },
      resetPassword() {
        // Enviar pedido
        var params = {
          email: this.editedAccountant.email
        };
        this.passwordReset(params).then(response => {
          if (response != null) {
            if (response.status == 200) {
              this.complete = true;
            }
          }
        });
      },
      onSuccess(userId) {
        this.dialog = false;
        this.$nextTick(() => {
          this.fetchAccountants();
        });
      },
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          // Reset em todas as opções
          this.editedAccountant = Object.assign({}, this.defaultAccountant);
          this.editedIndex = -1;
        });
      }
    }
  };
</script>