import axios from "axios";

const state = {
  identifier: "",
  password: "",
  loading: false,
  disabled: false,
  userData: null
};

const getters = {
  user: state => state.userData
};

const mutations = {
  setUserData(state, user) {
    state.userData = user;
    if (user == null) {
      state.identifier = "";
      state.password = "";
    }
  },

  updateIdentifier(state, identifier) {
    state.identifier = identifier;
  },

  updatePassword(state, password) {
    state.password = password;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  setDisabled(state, disabled) {
    state.disabled = disabled;
  }
};

const actions = {
  login({ commit }, data) {
    commit("setErrors", {}, { root: true });
    commit("updatePassword", "");
    return axios.post("/login", data).then(response => {
      commit("setUserData", response.data);
      localStorage.setItem("authToken", response.data.access_token);
    });
  },
  register({ commit }, data) {
    commit("setErrors", {}, { root: true });
    return axios.post("/register", data).then(response => {
      commit("setUserData", response.data);
      localStorage.setItem("authToken", response.data.access_token);
    });
  },
  logout({ commit }) {
    localStorage.removeItem("authToken");
    commit("setUserData", null);
    axios.post("/logout");
  },
  //getUserData({ commit }) {
  //  axios
  //    .get("/user")
  //    .then(response => {
  //      commit("setUserData", response.data);
  //    })
  //    .catch(() => {
  //      localStorage.removeItem("authToken");
  //    });
  //},
  fetchUser(_, __) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user")
        .then(response => resolve(response))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  updateUser(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/update", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getResetData(_, params) {
    localStorage.removeItem("authToken");
    return new Promise((resolve, reject) => {
      axios
        .get("/reset", { params })
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  },
  activate(_, payload) {
    localStorage.removeItem("authToken");
    return new Promise((resolve, reject) => {
      axios
        .post("/activate", payload)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  },
  passwordReset(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/password_reset", payload)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  },
  passwordChange(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/password_change", payload)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  },
  resendActivation(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/resend_activation", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
