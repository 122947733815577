<template>
  <div class="container">
    <v-card
      class="mx-auto"
      max-width="544"
      outlined
      v-if="this.accountantData === null"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Contabilista</div>
          <v-list-item-title class="text-h5 mb-1">
            Por atribuir
          </v-list-item-title>
          <v-list-item-subtitle>
            Fale com o seu Contabilista Octa Code para conseguir consultar e
            transmitir documentos
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar tile size="80" color="grey"></v-list-item-avatar>
      </v-list-item>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <div v-else>
      <document-browser
        :client_id="this.accountantData.client_id"
        :accountant_action="true"
      ></document-browser>
    </div>
  </div>
</template>

<script>
import DocumentBrowser from "../../components/DocumentBrowser.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      accountantData: null,
    };
  },
  computed: {
    ...mapGetters("auth", { user: "user" }),
  },
  created() {
    this.initialize();
  },
  watch: {
    dialogUpload(val) {
      val || this.closeUploadDialog();
    },
  },
  methods: {
    ...mapActions("client", ["accountant"]),
    initialize() {
      this.fetchAccountantData();
    },
    fetchAccountantData() {
      var params = {
        /* Vazio */
      };
      this.accountant(params).then((response) => {
        if (response != [] && response["error"] == undefined) {
          this.accountantData = response;
        } else {
          // Mostrar erro
          // TODO
        }
      });
    },
  },
  components: {
    DocumentBrowser,
  },
};
</script>