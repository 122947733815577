import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{attrs:{"color":"#1D2953","dark":""}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Licenças e documentos ")]),_c(VCardSubtitle,[_vm._v("Controle as suas licenças, consulte documentos e seguranças associados")]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","to":"/licenses"}},[_vm._v(" Ver agora ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{attrs:{"color":"#1D2953","dark":""}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Perfil utilizador ")]),_c(VCardSubtitle,[_vm._v("Mantenha o seu perfil atualizado para facilitar interações")]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","to":"/user"}},[_vm._v(" Ver perfil ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }