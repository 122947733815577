<template>
  <div class="container">
    <document-browser
      :client_id="this.client_id"
      :accountant_action="true"
    ></document-browser>
  </div>
</template>

<script>
import DocumentBrowser from "../../components/DocumentBrowser.vue";

export default {
  data() {
    return {
      client_id: this.$route.params["client_id"]
    };
  },

  methods: {},

  mounted() {},

  components: {
    DocumentBrowser,
  },
};
</script>
