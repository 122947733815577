<template>
  <v-container fluid fill-height>
    <v-layout style="height: 80vh">
      <v-flex style="overflow: auto">
        <v-data-table
          :headers="headers"
          :items="clientList"
          :search="search"
          sort-by="id"
          :loading="clientListLoading"
          loading-text="A carregar dados..."
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
            'show-current-page': true
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Procurar"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="700px" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    v-if="user.data.role == 'admin' || user.data.role == 'provider' || user.data.role == 'accountant'"
                  >
                    Novo Cliente
                  </v-btn>
                </template>
                <client-card
                  @userId="onSuccess"
                  @operationCancelled="onCancel"
                  v-if="dialog && (user.data.role == 'admin' || user.data.role == 'provider')"
                />
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editClient(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <p class="pt-4">Sem dados para mostrar</p>
            <v-btn class="mb-6" color="primary" @click="initialize">
              Update
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      bottom
      right
      :color="snackbarStatus"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ClientCard from "@/components/Cards/ClientCard.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    ClientCard
  },

  data: () => ({
    search: "",
    dialog: false,
    clientListLoading: true,
    headers: [
      { text: "Id", value: "id", align: "center" },
      { text: "Nome", value: "name", align: "start" },
      { text: "Empresa", value: "company", align: "start" },
      { text: "Email", value: "email", align: "center" },
      { text: "Criador", value: "creator.name", align: "center" },
      { text: "Ações", value: "actions", sortable: false, align: "center" }
    ],
    clientList: [],
    editedIndex: -1,
    editedClient: {
      id: 0,
      creator_id: 0,
      name: "",
      email: "",
      company: ""
    },
    defaultClient: {
      id: 0,
      creator_id: 0,
      name: "",
      email: "",
      company: ""
    },
    errors: [],
    snackbar: false,
    snackbarText: "",
    snackbarStatus: "success"
  }),

  computed: {
    ...mapGetters("auth", { user: "user" }),
    formTitle() {
      return this.editedIndex === -1 ? "Novo Cliente" : "Editar Cliente";
    },
    formSaveButton() {
      return this.editedIndex === -1 ? "Criar" : "Guardar";
    }
  },

  created() {
    this.initialize();
    // Remover coluna "Criador" se não for um Admin
    if(this.user.data.role != 'admin'){
      var headerCreator = this.headers.find(i => i.text === 'Criador');
      if(headerCreator != null){
        this.headers.splice(this.headers.indexOf(headerCreator), 1)
      }
    }
  },

  methods: {
    ...mapMutations("client", ["setClientData"]),
    ...mapMutations("client", ["setClientIndex"]),
    ...mapActions("client", ["getOwnedClients"]),
    fetchOwnClients: function() {
      this.getOwnedClients().then(response => {
        this.clientList = JSON.parse(JSON.stringify(response));
        this.clientListLoading = false;
      });
    },
    initialize() {
      this.fetchOwnClients();
    },
    editClient(item) {
      this.editedIndex = this.clientList.indexOf(item);
      this.editedClient = Object.assign({}, this.clientList[this.editedIndex]);

      this.$nextTick(() => {
        if (this.editedIndex != -1) {
          this.editedClient.creator_id = parseInt(this.editedClient.creator_id);
        }
        this.setClientData(this.editedClient);
        this.setClientIndex(this.editedIndex);
        this.dialog = true;
      });
    },
    onSuccess(userId) {
      this.dialog = false;
      this.$nextTick(() => {
        if (this.editedIndex != -1) {
          this.showSnackbar("Cliente editado", "success");
        } else {
          this.showSnackbar("Cliente criado", "success");
        }
        this.resetClientStore();
        this.fetchOwnClients();
      });
    },
    onCancel(operationCancelled) {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetClientStore();
      });
    },
    resetClientStore() {
      this.editedClient = Object.assign({}, this.defaultClient);
      this.editedIndex = -1;
      this.setClientData(this.editedClient);
      this.setClientIndex(-1);
    },
    showSnackbar(text, status) {
      this.snackbar = true;
      this.snackbarText = text;
      this.snackbarStatus = status;
    }
  }
};
</script>
