<template>
  <v-container fluid fill-height>
    <v-layout style="height: 80vh">
      <v-flex style="overflow: auto">
        <v-data-table
          :headers="headers"
          :items="partnerList"
          sort-by="id"
          :loading="partnerListLoading"
          loading-text="A carregar dados..."
          :footer-props="{
            'items-per-page-options': [10, 25, 50]
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="700px" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Novo Parceiro
                  </v-btn>
                </template>

                <v-card :loading="cardLoading">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedPartner.email"
                            :error-messages="errors['email']"
                            label="Email"
                            v-bind:disabled="editedIndex != -1"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedPartner.name"
                            :error-messages="errors['name']"
                            label="Nome do responsável"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedPartner.company"
                            :error-messages="errors['company']"
                            label="Empresa"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  
                  <v-card-actions class="justify-center" v-if="editedIndex != -1 && user.data.role == 'admin'">
                    <v-btn color="darken-1" @click="resendActivationButton">
                      Reenviar ativação
                    </v-btn>
                    <v-btn color="darken-1" @click="resetPassword">
                      Reset Password
                    </v-btn>
                  </v-card-actions>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-bind:disabled="cardLoading" color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn v-bind:disabled="cardLoading" color="primary" depressed text @click="savePartner">
                      {{ formSaveButton }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editPartner(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <p class="pt-4">Sem dados para mostrar</p>
            <v-btn class="mb-6" color="primary" @click="initialize">
              Update
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    dialog: false,
    partnerListLoading: true,
    headers: [
      { text: "Id", value: "id", align: "center" },
      { text: "Nome", value: "name", align: "start" },
      { text: "Empresa", value: "company", align: "start" },
      { text: "Email", value: "email", align: "center" },
      { text: "Criador", value: "admin.name", align: "center" },
      { text: "Ações", value: "actions", sortable: false, align: "center" }
    ],
    partnerList: [],
    editedIndex: -1,
    editedPartner: {
      id: 0,
      creator_id: 0,
      name: "",
      email: "",
      company: ""
    },
    defaultPartner: {
      id: 0,
      creator_id: 0,
      name: "",
      email: "",
      company: ""
    },
    cardLoading: false,
    errors: []
  }),

  computed: {
    ...mapGetters("auth", { user: "user" }),
    formTitle() {
      return this.editedIndex === -1 ? "Novo Parceiro" : "Editar Parceiro";
    },
    formSaveButton() {
      return this.editedIndex === -1 ? "Criar" : "Guardar";
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("partner", ["store"]),
    ...mapActions("partner", ["update"]),
    ...mapActions("partner", ["getPartners"]),
    ...mapActions("auth", ["passwordReset"]),
    ...mapActions("auth", ["resendActivation"]),

    fetchPartners: function() {
      this.getPartners().then(response => {
        this.partnerList = JSON.parse(JSON.stringify(response));
        this.partnerListLoading = false;
      });
    },
    initialize() {
      this.fetchPartners();
    },
    savePartner() {
      this.errors = {};
      this.cardLoading = true;

      if (this.editedIndex == -1) {
        // Novo Parceiro
        this.store(this.editedPartner).then(response => {
            this.cardLoading = false;
            var errors = this.$store.state.errors;
            if (errors.length === 0) {
              // Adicionado com sucesso
              this.onSuccess(response.data.attributes.id);
            } else {
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
            }
            this.$store.commit("setErrors", []);
          })
          .catch(error => {
            this.cardLoading = false;
            alert(error);
          });
      } else {
        // Parceiro a editar
        this.update(this.editedPartner).then(response => {
          this.cardLoading = false;
          var errors = this.$store.state.errors;
          if (errors.length === 0) {
            // Adicionado com sucesso
            this.onSuccess(-1);
          } else {
            // Existem erros
            this.errors = JSON.parse(JSON.stringify(errors));
          }
          this.$store.commit("setErrors", []);
        }).catch(error => {
          this.cardLoading = false;
          alert(error);
        });
      }
    },
    editPartner(item) {
      this.editedIndex = this.partnerList.indexOf(item);
      this.editedPartner = Object.assign(
        {},
        this.partnerList[this.editedIndex]
      );

      this.$nextTick(() => {
        if (this.editedIndex != -1) {
          this.editedPartner.creator_id = parseInt(
            this.editedPartner.creator_id
          );
        }
        this.dialog = true;
      });
    },
    resendActivationButton() {
      var payload = {
        user_id: this.editedPartner.id
      };

      this.resendActivation(payload)
        .then(response => {
          // TODO
        })
        .catch(err => {
        });
    },
    resetPassword() {
      // Enviar pedido
      var params = {
        email: this.editedPartner.email
      };
      this.passwordReset(params).then(response => {
        if (response != null) {
          if (response.status == 200) {
            this.complete = true;
          }
        }
      });
    },
    onSuccess(userId) {
      this.dialog = false;
      this.$nextTick(() => {
        this.fetchPartners();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // Reset em todas as opções
        this.editedPartner = Object.assign({}, this.defaultPartner);
        this.editedIndex = -1;
      });
    }
  }
};
</script>
