import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{staticStyle:{"height":"80vh"}},[_c(VFlex,{staticStyle:{"overflow":"auto"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.clientList,"search":_vm.search,"sort-by":"id","loading":_vm.clientListLoading,"loading-text":"A carregar dados...","footer-props":{
          'items-per-page-options': [10, 25, 50],
          'show-current-page': true
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle),_c(VTextField,{attrs:{"prepend-icon":"mdi-magnify","label":"Procurar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VDialog,{attrs:{"max-width":"700px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.user.data.role == 'admin' || _vm.user.data.role == 'provider' || _vm.user.data.role == 'accountant')?_c(VBtn,_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Novo Cliente ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog && (_vm.user.data.role == 'admin' || _vm.user.data.role == 'provider'))?_c('client-card',{on:{"userId":_vm.onSuccess,"operationCancelled":_vm.onCancel}}):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editClient(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"pt-4"},[_vm._v("Sem dados para mostrar")]),_c(VBtn,{staticClass:"mb-6",attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Update ")])]},proxy:true}],null,true)})],1)],1),_c(VSnackbar,{attrs:{"timeout":3000,"bottom":"","right":"","color":_vm.snackbarStatus},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" OK ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }