import axios from "axios";

const state = {
  licenseData: {
    id: 0,
    license_code: ""
  }
};

const getters = {
  getLicenseData: state => state.licenseData
};

const mutations = {
  setLicenseData(state, payload) {
    state.licenseData = payload;
  }
};

const actions = {
  index({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/backups", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  companies({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/backups/companies", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  list({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/backups/list", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  download(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/backups/download", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  delete(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/backups/delete", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
