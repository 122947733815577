<template>
  <div>
    <div class="text-center">
      <h1 class="headline mb-2">
        Reset de password
      </h1>
      <span class="d-inline-block mb-8">Altere a sua password</span>
    </div>

    <v-form>
      <v-text-field prepend-icon="person" v-model="user.name" name="name" label="Nome" type="text" outlined disabled>
      </v-text-field>
      <v-text-field prepend-icon="mdi-at" v-model="user.email" name="email" label="Email" type="text" outlined disabled>
      </v-text-field>
      <v-text-field prepend-icon="domain" class="mb-10" v-model="user.company" name="company" label="Empresa"
        type="text" outlined disabled></v-text-field>
      <v-text-field class="mb-10" prepend-icon="lock" :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
        label="Nova Password" name="password" v-model="user.password" :type="show ? 'input' : 'password'"
        hide-details="auto" outlined @click:append="show = !show" :disabled="disabled" />
      <v-text-field class="mb-10" prepend-icon="mdi-lock-check"
        :append-icon="showConfirm ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" label="Confirmar Password"
        v-model="user.password_confirm" name="password_confirm" :type="showConfirm ? 'input' : 'password'"
        hide-details="auto" outlined @click:append="showConfirm = !showConfirm" :disabled="disabled"></v-text-field>
    </v-form>

    <v-alert prominent type="error" dense v-if="error">
      {{ this.error }}
    </v-alert>

    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn class="text-none" style="min-width: 88px;" color="primary" depressed @click="resetPassword">
        Confirmar
      </v-btn>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  export default {
    data: () => ({
      error: null,
      disabled: false,
      show: false,
      showConfirm: false,
      overlay: true,
      user: {
        name: "",
        email: "",
        company: "",
        password: "",
        password_confirm: "",
        reset_token: ""
      }
    }),
    methods: {
      ...mapActions("auth", ["login"]),
      ...mapActions("auth", ["getResetData"]),
      ...mapActions("auth", ["passwordChange"]),

      fetchResetData() {
        this.user.reset_token = this.$route.params["resetToken"];
        var params = {
          reset_token: this.user.reset_token
        };
        this.getResetData(params).then(response => {
          if (response.data.attributes != null) {
            // Token existe, parse nos dados
            var json = response.data.attributes;
            if (json.id != null) {
              this.user.name = json.name;
              this.user.email = json.email;
              this.user.company = json.company;
              this.overlay = false;
              return;
            }
          } else {
            var errors = this.$store.state.errors;
            if (errors.length > 0) {
              this.error = errors;
            }
          }

          // Se chegar aqui, token não existe, redirecionar para a página principal
          this.$store.commit("setErrors", []);
          this.$router.push("/");
        });
      },

      resetPassword() {
        this.error = null;

        if (this.user.password != this.user.password_confirm) {
          this.error = "Passwords não são iguais";
          return;
        }

        if (this.user.password.length < 6) {
          this.error = "A password deve ter pelo menos 6 caracteres";
          return;
        }

        this.disabled = true;
        this.passwordChange(this.user).then(() => {
          var errors = this.$store.state.errors;
          if (errors.length === 0) {
            // Password alterada com sucesso
            localStorage.removeItem("authToken");
            var loginUser = {
              email: this.user.email,
              password: this.user.password
            };
            this.login(loginUser).then(() => {
              this.$router.push("/");
            });
          } else {
            // Existem erros
            this.disabled = false;
            this.error = JSON.parse(JSON.stringify(errors));
          }
          this.$store.commit("setErrors", []);
        });
      }
    },
    mounted() {
      this.fetchResetData();
    }
  };
</script>