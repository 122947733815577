<template>
  <v-layout style="height: 80vh">
    <v-dialog v-model="uploadCard" max-width="60vw" persistent>
      <document-upload-card 
        :license_code="$route.params['license_code']" 
        :company="selectedCompany"
        :client_id="this.client_id" 
        :user="this.user.data"
        @dialogUploadClosed="closeUploadDialog" 
        @dialogUploadedFiles="closeUploadDialogAndRefresh" 
        />
    </v-dialog>
    <v-dialog v-model="documentEditCard" max-width="60vw">
      <document-edit-card 
        :clientId="this.client_id" 
        :document="this.selectedDocument"
        :tab="this.tabItems[this.tab].key" 
        :company="this.selectedCompany" 
        :user="this.user.data"
        @dialogEditClosed="closeEditDialog"
      />
    </v-dialog>
    <v-flex style="overflow: auto">
      <v-card style="width: 75vw; margin: auto">
        <v-toolbar color="#3F51B5" dark flat height="100px">
          <v-select 
            class="pt-8" 
            dense 
            outlined 
            v-model="selectedCompany" 
            :items="companyList" 
            label="Empresa"
            @input="handleSelectedCompanyChanged"
          />
          <v-spacer></v-spacer>
          <v-btn icon v-if="selectedCompany != null" @click="uploadCard = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn icon @click="refreshTab">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ma-2 white--text">
                {{ selectedYear }}
                <v-icon right>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="year in years" :key="year">
                <v-list-item-title @click="setSelectedYear(year)">{{ year }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title @change="selectedTabChanged()">
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab v-for="tabItem in tabItems" :key="tabItem.name">
                {{ tabItem.name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tabItem in tabItems" :key="tabItem.name">
            <v-data-table :headers="itemHeaders" :loading="itemsLoading" :items="tabItem.items"
              loading-text="A carregar dados... Por favor aguarde" no-data-text="Sem dados para mostrar" :footer-props="{
                'items-per-page-options': [50, 100, 200],
              }">
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="editDocument(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Alterar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="downloadDocument(item)">
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import DocumentUploadCard from "./Cards/DocumentUploadCard.vue";
import DocumentEditCard from "./Cards/DocumentEditCard.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    license_code: String,
    client_id: String,
    accountant_action: Boolean,
  },
  data() {
    return {
      tab: 0,
      tabItems: [
        {
          name: "Geral",
          key: "geraldocs",
          anual: false,
          items: [],
          loaded: false,
        },
        {
          name: "Dossier Fiscal",
          key: "geraldossierfiscal",
          anual: false,
          items: [],
          loaded: false,
        },
        {
          name: "Contabilidade Anual",
          key: "docscontabilidade",
          anual: true,
          items: [],
          loaded: false,
        },
        {
          name: "Dossier Anual",
          key: "dossierfiscal",
          anual: true,
          items: [],
          loaded: false,
        },
        {
          name: "SAF-T",
          key: "saft",
          anual: false,
          items: [],
          loaded: false,
        },
      ],
      companyList: [],
      selectedCompany: null,
      companyChangedTimeout: null,
      years: [],
      selectedYear: new Date().getFullYear(),
      itemsLoading: true,
      isRefreshing: false,
      itemHeaders: [
        { text: "Documento", value: "name", align: "center" },
        { text: "Data", value: "date", align: "start" },
        { text: "Tamanho", value: "size", align: "center", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
      text: "A carregar...",
      uploadCard: false,
      selectedDocument: null,
      documentEditCard: false,
    };
  },
  watch: {
    dialogUpload(val) {
      val || this.closeUploadDialog();
    },
    dialogEdit(val) {
      val || this.closeEditDialog();
    }
  },
  mounted() {
    this.generateYears();
    this.fetchCompanies();
  },
  methods: {
    ...mapActions("documentstorage", ["index", "listObjects", "download"]),
    fetchCompanies() {
      var params = this.generateParams();
      this.index(params)
        .then((res) => JSON.parse(JSON.stringify(res)))
        .then((json) => {
          this.companyList = [];
          json.forEach((lineObserver) => {
            var line = JSON.parse(JSON.stringify(lineObserver));
            //var companyName = line.split("/")[1];
            if (!this.companyList.includes(line)) {
              this.companyList.push(line);
            }
          });
          this.selectedCompany = this.companyList[0];
          this.selectedCompanyChanged();
        });
    },
    fetchObjects(companyName, tab, yearValue) {         
      this.itemsLoading = true;   
      if(!this.isRefreshing) {
        this.isRefreshing = true;
      }else{
        return;
      }

      tab.items = [];

      var params = this.generateParams();
      params["company"] = companyName;
      params["document_type"] = tab.key;
      params["accountant_action"] = this.accountant_action;

      if (tab.anual) {
        params["year"] = yearValue;
      }

      this.listObjects(params)
        .then((res) => JSON.parse(JSON.stringify(res)))
        .then((json) => {
          json.forEach((docObserver) => {
            var docJson = JSON.parse(JSON.stringify(docObserver));
            var doc = {
              name: docJson["Key"].split("/").at(-1), // -1 = Último elemento do array
              date: docJson["LastModified"],
              size: this.formatBytes(docJson["Size"], 2),
            };
            tab.items.push(doc);
          });
          tab.loaded = true;
          this.itemsLoading = false;
          this.isRefreshing = false;
        });
    },
    editDocument(item) {
      var jsonItem = JSON.stringify(item);
      this.selectedDocument = jsonItem;
      this.documentEditCard = true;
    },
    downloadDocument(item) {
      var item = JSON.parse(JSON.stringify(item));

      var params = this.generateParams();
      params["company"] = this.selectedCompany;
      params["document_type"] = this.tabItems[this.tab].key;
      params["file_name"] = item.name;

      if (this.tabItems[this.tab].anual) {
        params["year"] = this.selectedYear;
      }

      this.download(params)
        .then((res) => JSON.parse(JSON.stringify(res)))
        .then((downloadUrl) => {
            window.open(downloadUrl, "_blank");
        });
    },
    generateYears() {
      for (let year = this.selectedYear; year >= 2021; year--) {
        this.years.push(year.toString());
      }
    },
    setSelectedYear(selectedValue) {
      this.selectedYear = selectedValue;
      this.resetAllTabData();

      var currentTab = this.tabItems[this.tab];
      this.fetchObjects(this.selectedCompany, currentTab, this.selectedYear);
    },
    handleSelectedCompanyChanged() {
      clearTimeout(this.companyChangedTimeout);
      this.companyChangedTimeout = setTimeout(() => {
        this.selectedCompanyChanged();
      }, 500);
    },
    selectedCompanyChanged() {
      this.resetAllTabData();

      var currentTab = this.tabItems[this.tab];
      this.fetchObjects(this.selectedCompany, currentTab, this.selectedYear);
    },
    selectedTabChanged() {
      var newTab = this.tabItems[this.tab];
      if (!newTab.loaded) {
        this.fetchObjects(this.selectedCompany, newTab, this.selectedYear);
      }
    },
    refreshTab() {
      var currentTab = this.tabItems[this.tab];
      this.fetchObjects(this.selectedCompany, currentTab, this.selectedYear);
    },
    resetAllTabData() {
      for (var tab of this.tabItems) {
        tab.loaded = false;
        tab.items = [];
      }
    },
    closeUploadDialog() {
      this.uploadCard = false;
    },
    closeUploadDialogAndRefresh() {
      this.uploadCard = false;
      this.refreshTab();
    },
    closeEditDialog() {
      this.documentEditCard = false;
      this.refreshTab();
    },
    generateParams() {
      var params = {
        client_id: null,
        license_code: null,
      };
      if (this.client_id != undefined) {
        params["client_id"] = this.client_id;
      }
      if (this.license_code != undefined && this.license_code != '') {
        params["license_code"] = this.license_code;
      }
      if (this.selectedLicense != undefined && this.selectedLicense != '') {
        params["license_code"] = this.selectedLicense;
      }
      console.log(params);
      return params;
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
  components: {
    DocumentUploadCard, DocumentEditCard,
  },
  computed: {
    ...mapGetters("auth", { user: "user" }),
    ...mapGetters("documentstorage", { selectedLicense: "selectedLicense" })
  },
};
</script>
