<template>
  <div>
    <div v-if="isLoading || licenses.length == 0">
      <v-container fluid fill-height style="height: 70vh;">
        <div v-if="isLoading" style="margin: auto; justify-content: center;">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
          <h3>A carregar dados...</h3>
        </div>
        <v-card style="margin: auto" v-else elevation="2" max-width="444">
          <v-card-text>
            <div class="mb-2 mr-2">
              <v-icon>info</v-icon> Informação
            </div>
            <p class="text-h4 text--primary">
              Licença não encontrada
            </p>
            <div class="text--primary">
              Não tem nenhuma licença associada<br />
              Contacte o seu revendedor ou o suporte
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <div v-else>
      <v-card>
        <v-toolbar color="#3F51B5" dark>
          <v-select class="pt-8" v-model="selectedLicenseCode" :items="licenseList" label="Licença" dense outlined
            @input="selectedLicenseChanged"></v-select>

          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="white"></v-tabs-slider>

              <v-tab>Geral</v-tab>
              <v-tab>Módulos</v-tab>
              <v-tab>Seguranças</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <!-- Detalhes -->
            <v-spacer></v-spacer>
            <v-card class="pt-6">
              <v-card-title>
                <span class="headline">Detalhes</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="selectedLicense.license_code" :error-messages="errors['license_code']"
                      label="Chave da Licença" readonly prepend-icon="mdi-key"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Produto" v-model="applicationName" readonly prepend-icon="mdi-tag">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field v-model="selectedLicense.user_limit" :error-messages="errors['user_limit']"
                      label="Nº Utilizadores" type="number" min="1" readonly prepend-icon="mdi-account-group">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selectedLicense.enterprise_limit"
                      :error-messages="errors['enterprise_limit']" label="Nº Empresas" type="number" min="1" readonly
                      prepend-icon="mdi-briefcase"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selectedLicense.mobile_limit" :error-messages="errors['mobile_limit']"
                      label="Dispositivos Móveis" type="number" min="0" readonly prepend-icon="mdi-cellphone">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <v-text-field v-model="selectedLicense.expiry_date" :error-messages="errors['expiry_date']"
                      label="Validade" prepend-icon="mdi-calendar" readonly></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="expiryDateTotalDays" label="Total em dias" readonly />
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pt-6">
                  <v-card-title>
                    <span class="headline">Opções</span>
                  </v-card-title>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col cols="12">
                        <div class="text-center">
                          <div class="my-2">
                            <v-btn color="primary" dark @click="openLicenseDocuments">
                              Documentos Cloud
                            </v-btn>
                          </div>
                          <!--
                          <div class="my-2">
                            <v-btn color="primary" dark>
                              Small Button
                            </v-btn>
                          </div>
                          <div class="my-2">
                            <v-btn color="primary" dark>
                              Normal Button
                            </v-btn>
                          </div>
                          <div class="my-2">
                            <v-btn color="primary" dark>
                              Large Button
                            </v-btn>
                          </div>
                          <div class="my-2">
                            <v-btn color="primary" dark>
                              Extra large Button
                            </v-btn>
                          </div>
                          -->
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- Módulos -->
            <v-card class="ma-6">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header>Gerais</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list disabled>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in $octaModules" :key="i">
                          <v-list-item-icon v-if="
                              currentModules[item.id] &&
                                currentModules[item.id].enabled
                            ">
                            <v-icon color="success" v-text="checkIcon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-icon v-else>
                            <v-icon color="error" v-text="closeIcon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.value"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>Faturação
                    <template v-slot:actions>
                      <v-icon color="success" v-if="currentModules[8] && currentModules[8].enabled" v-text="checkIcon">
                      </v-icon>
                      <v-icon color="error" v-else v-text="closeIcon"></v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list disabled>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in $octaModulesFaturacao" :key="i">
                          <v-list-item-icon v-if="
                              currentModules[item.id] &&
                                currentModules[item.id].enabled
                            ">
                            <v-icon color="success" v-text="checkIcon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-icon v-else>
                            <v-icon color="error" v-text="closeIcon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.value"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>Contabilidade
                    <template v-slot:actions>
                      <v-icon color="success" v-if="currentModules[7] && currentModules[7].enabled" v-text="checkIcon">
                      </v-icon>
                      <v-icon color="error" v-else v-text="closeIcon"></v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list disabled>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in $octaModulesContabilidade" :key="i">
                          <v-list-item-icon v-if="
                              currentModules[item.id] &&
                                currentModules[item.id].enabled
                            ">
                            <v-icon color="success" v-text="checkIcon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-icon v-else>
                            <v-icon color="error" v-text="closeIcon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.value"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- Backups -->
            <backups-card :key="selectedLicenseCode"></backups-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
  import BackupsCard from "@/components/Cards/BackupsCard.vue";
  import {
    mapActions,
    mapMutations
  } from "vuex";

  export default {
    components: {
      BackupsCard
    },

    data: () => ({
      isLoading: true,
      tab: null,
      errors: [],
      panel: [0],

      selectedLicenseCode: "",
      licenses: [],
      licenseList: [],
      selectedLicense: {
        id: 0,
        license_code: "",
        application_id: 1,
        user_limit: 1,
        enterprise_limit: 1,
        mobile_limit: 0,
        update_limited: false,
        modules: "00000000000000000000000000000000",
        activated: 0,
        license_status: "",
        license_duration: 0,
        license_hash: "",
        license_guid: "",
        activation_date: "1900-01-01 00:00:00",
        expiry_date: new Date().toISOString().substr(0, 10)
      },
      currentModules: [{
        id: 0,
        module: "",
        enabled: false
      }],
      applicationName: "Octa Gest",
      expiryDateTotalDays: "0 dias",

      checkIcon: "mdi-check-circle-outline",
      closeIcon: "mdi-close-circle-outline"
    }),
    created() {
      this.initialize();
    },
    methods: {
      ...mapActions("license", ["getOwnedLicenses"]),
      ...mapMutations("documentstorage", ["setSelectedLicense"]),
      ...mapMutations("backup", ["setLicenseData"]),
      initialize() {
        // Obter lista licenças
        this.fetchOwnedLicenses();
      },
      fetchOwnedLicenses() {
        this.getOwnedLicenses().then(response => {
          this.isLoading = false;
          var jsonLicenses = response;

          jsonLicenses.forEach(element => {
            this.licenseList.push(element.license_code);
            const [expiry_date, garbage] = element.expiry_date.split(" ");
            element.expiry_date = expiry_date;
          });

          this.licenses = jsonLicenses;

          // Selecionar primeira licença e obter dados dessa
          if (this.licenses.length > 0) {
            this.selectedLicenseChanged(this.licenses[0].license_code);
          }
        });
      },
      selectedLicenseChanged(license) {
        this.selectedLicenseCode = license;
        this.selectedLicense = this.licenses.find(i => i.license_code == license);

        this.setLicenseData(this.selectedLicense);
        this.parseCurrentModules(this.selectedLicense);
        this.parseDaysRemaining(this.selectedLicense["expiry_date"]);
      },
      openLicenseDocuments() {
        this.$nextTick(() => {
          this.setSelectedLicense(this.selectedLicense.license_code);
          this.$router.push(`accountant/documents/${this.selectedLicense.owner_id}`);
        });
      },
      parseCurrentModules(license) {
        this.currentModules = [];
        for (var i = 0; i < license["modules"].length; i++) {
          var enabled = false;
          if (license["modules"][i] == 1) {
            enabled = true;
          }

          var modItem = {
            id: i,
            enabled: enabled
          };

          if (this.$octaModules.find(m => m.id == i)) {
            let obj = this.$octaModules.find(m => m.id == i);
            modItem.module = obj.value;
          } else if (this.$octaModulesFaturacao.find(m => m.id == i)) {
            let obj = this.$octaModulesFaturacao.find(m => m.id == i);
            modItem.module = obj.value;
          } else if (this.$octaModulesContabilidade.find(m => m.id == i)) {
            let obj = this.$octaModulesContabilidade.find(m => m.id == i);
            modItem.module = obj.value;
          }

          this.currentModules.push(modItem);
        }
      },
      parseDaysRemaining(expiryDate) {
        var currentDate = new Date().getTime();
        var expiryDate = new Date(expiryDate).getTime();
        var daysDiff = Math.floor(
          (expiryDate - currentDate) / (1000 * 3600 * 24)
        );
        this.expiryDateTotalDays = daysDiff + " dias";
      }
    }
  };
</script>