<template>
  <v-card :loading="cardLoading">
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="editedClient.email"
              :error-messages="errors['email']"
              label="Email"
              v-bind:disabled="
                clientIndex != -1 && this.user.data.role !== 'admin'
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editedClient.name"
              :error-messages="errors['name']"
              label="Nome do responsável"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editedClient.company"
              :error-messages="errors['company']"
              label="Empresa"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="isAccountant"
              v-if="isClient"
              :label="`Contabilista`"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions class="justify-center" v-if="clientIndex != -1 && user.data.role == 'admin'">
      <v-btn color="darken-1" @click="resendActivationButton">
        Reenviar ativação
      </v-btn>
      <v-btn color="darken-1" @click="resetPassword">
        Reset Password
      </v-btn>
    </v-card-actions>

    <v-card-actions class="mt-4">
      <v-spacer></v-spacer>
      <v-btn v-bind:disabled="cardLoading" color="blue darken-1" text @click="close">
        Cancelar
      </v-btn>
      <v-btn v-bind:disabled="cardLoading" color="primary" depressed text @click="saveClient">
        {{ formSaveButton }}
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="snackbar"
      :timeout="6000"
      bottom
      right
      :color="snackbarStatus"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "client-card",

  data: () => ({
    editedClient: {
      id: 0,
      creator_id: 0,
      name: "",
      email: "",
      company: "",
      role: "client",
    },
    clientIndex: -1,
    cardLoading: false,
    errors: [],
    snackbar: false,
    snackbarText: "",
    snackbarStatus: "success",

    isClient: false, // accountant também conta como client
    isAccountant: false,
  }),

  mounted() {
    this.editedClient = this.clientData;
    this.clientIndex = this.clientIndexData;
    this.isAccountant = this.editedClient.role == "accountant";
    this.isClient = this.editedClient.role == "client" || this.editedClient.role == "accountant";
  },

  computed: {
    ...mapGetters("auth", { user: "user" }),
    ...mapGetters("client", {
      clientData: "getClientData",
      clientIndexData: "getClientIndex"
    }),
    formTitle() {
      return this.clientIndex == -1 ? "Novo Cliente" : "Editar Cliente";
    },
    formSaveButton() {
      return this.clientIndex == -1 ? "Criar" : "Guardar";
    }
  },

  methods: {
    ...mapActions("client", ["store", "update"]),
    ...mapActions("auth", ["passwordReset", "resendActivation"]),
    saveClient() {
      this.errors = {};

      if (!this.validEmail(this.editedClient.email)) {
        this.errors["email"] = "Introduza um email válido";
        this.$refs.input.focus();
        return;
      }

      if(this.isAccountant && this.editedClient.role == "client") {
        this.editedClient.role = "accountant";
      }else if (!this.isAccountant && this.editedClient.role == "accountant") {
        this.editedClient.role = "client";
      }

      this.cardLoading = true;
      if (this.clientIndex == -1) {
        // Novo cliente
        this.store(this.editedClient)
          .then(response => {
            this.cardLoading = false;
            // Adicionado com sucesso
            this.success(response.data.attributes.id);
          })
          .catch(_ => {
            this.cardLoading = false;
            var errors = this.$store.state.errors;
            if(errors.length > 0){              
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
              this.showSnackbar(this.errors, "error");
            }else{
              this.showSnackbar("Erro inesperado, contacte o suporte", "error");
            }
            this.$store.commit("setErrors", []);
          });
      } else {
        // Cliente editado
        this.update(this.editedClient)
          .then(response => {
            this.cardLoading = false;
            var errors = this.$store.state.errors;
            if (errors.length === 0) {
              // Adicionado com sucesso
              this.success(-1);
            } else {
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
              var errorStr = Object.values(this.errors)[0].toString();
              errorStr = errorStr.replace('["').replace('"]');
              this.showSnackbar(errorStr, "error");
            }
            this.$store.commit("setErrors", []);
          })
          .catch(error => {
            this.cardLoading = false;
            this.showSnackbar(error, "error");
          });
      }
    },
    resendActivationButton() {
      var payload = {
        user_id: this.editedClient.id
      };

      this.resendActivation(payload)
        .then(response => {
          // TODO
        })
        .catch(err => {
        });
    },
    resetPassword() {
      // Enviar pedido
      var params = {
        email: this.editedClient.email
      };
      this.passwordReset(params).then(response => {
        if (response != null) {
          if (response.status == 200) {
            this.complete = true;
          }
        }
      });
    },
    success(id) {
      this.$emit("userId", id);
    },
    close() {
      this.$emit("operationCancelled", -1);
    },
    showSnackbar(text, status) {
      this.snackbar = true;
      this.snackbarText = text;
      this.snackbarStatus = status;
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>
