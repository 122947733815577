import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{staticStyle:{"height":"80vh"}},[_c(VFlex,{staticStyle:{"overflow":"auto"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.accountantList,"sort-by":"id","loading":_vm.accountantListLoading,"loading-text":"A carregar dados...","footer-props":{
          'items-per-page-options': [10, 25, 50]
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.associateClients(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple-plus ")])]}}],null,true)},[_c('span',[_vm._v("Clientes")])])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"pt-4"},[_vm._v("Sem dados para mostrar")]),_c(VBtn,{staticClass:"mb-6",attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Update ")])]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }