<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>
      <sidebar-link to="/user">
        <md-icon>person</md-icon>
        <p>Perfil</p>
      </sidebar-link>
      <sidebar-link to="/admin" v-if="this.user.data.role == 'admin'">
        <md-icon>tune</md-icon>
        <p>Administração</p>
      </sidebar-link>
      <sidebar-link to="/partners" v-if="this.user.data.role == 'admin'">
        <md-icon>assignment_ind</md-icon>
        <p>Parceiros</p>
      </sidebar-link>
      <sidebar-link to="/accountants" v-if="this.user.data.role == 'admin'">
        <v-icon>mdi-account-cash</v-icon>
        <p>Contabilistas</p>
      </sidebar-link>
      <sidebar-link to="/clients" v-if="this.user.data.role == 'admin' || this.user.data.role == 'provider'">
        <md-icon>people</md-icon>
        <p>Clientes</p>
      </sidebar-link>
      <sidebar-link to="/accountant/clients" v-if="this.user.data.role == 'accountant'">
        <md-icon>people</md-icon>
        <p>Clientes</p>
      </sidebar-link>
      <sidebar-link to="/client/accountant" v-if="this.user.data.role == 'client'">
        <md-icon>people</md-icon>
        <p>Contabilista</p>
      </sidebar-link>
      <sidebar-link to="/licensing" v-if="this.user.data.role == 'admin' || this.user.data.role == 'provider'">
        <md-icon>content_paste</md-icon>
        <p>Gestor Licenças</p>
      </sidebar-link>
      <sidebar-link to="/licenses">
        <md-icon>topic</md-icon>
        <p>Licenças</p>
      </sidebar-link>
      <sidebar-link to="/tickets" v-if="this.user.data.role == 'admin'">
        <md-icon>support</md-icon>
        <p>Tickets Suporte</p>
      </sidebar-link>
      <sidebar-link to="#" class="active-pro">
        <md-icon>copyright</md-icon>
        <p>{{ year }} Octa Code</p>
      </sidebar-link>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </side-bar>

    <div>
      <div class="main-panel">
        <top-navbar></top-navbar>
        <v-container fluid>
          <v-row class="fill-height">
            <v-col>
              <dashboard-content> </dashboard-content>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./DashboardContent.vue";
import MobileMenu from "./MobileMenu.vue";

export default {
  computed: {
    ...mapGetters("auth", { user: "user" })
  },
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu
  },
  data: () => ({
    year: new Date().getFullYear(),
    sidebarBackground: "blue",
    sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg")
  }),
  methods: {}
};
</script>
