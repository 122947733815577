<template>
  <div>
    <div v-if="!complete">
      <div class="text-center">
        <h1 class="headline mb-2">
          Reset de password
        </h1>
        <span class="d-inline-block mb-8">Introduza o seu email associado</span>
      </div>
      <v-form @submit.prevent="next">
        <v-text-field
          ref="input"
          v-model="identifier"
          class="mb-10"
          label="Email"
          name="login"
          type="text"
          hide-details="auto"
          outlined
          :disabled="disabled"
          :error-messages="error"
        />
        <div style="margin-top: -16px; width: 304px; margin: 0 auto;">
          <vue-recaptcha
            :sitekey="recaptchaSiteKey"
            :loadRecaptchaScript="true"
            @verify="captchaVerified"
            @expired="captchaExpired"
            @error="captchaError"
          >
          </vue-recaptcha>
          <div v-if="showCaptchaError" class="text-center red white--text">
            <strong>Complete o Captcha antes de continuar</strong>
          </div>
        </div>
      </v-form>

      <div class="d-flex justify-space-between" style="margin-top: 24px;">
        <v-btn
          class="text-none letter-spacing-0 font-weight-bold"
          style="margin-left: -16px;"
          color="primary"
          text
          @click="$router.push({ name: 'password' })"
        >
          Voltar atrás
        </v-btn>
        <v-btn
          class="text-none"
          style="min-width: 88px;"
          color="primary"
          depressed
          @click="next"
        >
          Pedir nova password
        </v-btn>
      </div>
    </div>

    <div v-if="complete">
      <div class="text-center">
        <h1 class="headline mb-2">
          Reset de password
        </h1>
        <span class="d-inline-block mb-8">Email enviado com sucesso</span>
        <div class="d-block mb-8">
          <v-icon color="green" size="128">
            mdi-check-circle-outline
          </v-icon>
        </div>
        <span class="d-inline-block mb-8">
          <p>Deverá recebê-lo dentro de dois minutos.</p>
          <p>Verifique a sua pasta de Spam caso não o encontre.</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VueRecaptcha from "vue-recaptcha";

export default {
  data: () => ({
    error: null,
    disabled: false,
    complete: false,
    recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY,

    showCaptchaError: false,
    captchaValid: false
  }),
  components: { VueRecaptcha },
  computed: {
    identifier: {
      get() {
        return this.$store.state.auth.identifier;
      },
      set(value) {
        this.$store.commit("auth/updateIdentifier", value);
      }
    }
  },
  methods: {
    ...mapActions("auth", ["passwordReset"]),

    back() {},
    async next() {
      if (!this.validEmail(this.identifier)) {
        this.error = "Introduza um email válido";
        this.$refs.input.focus();
        return;
      }
      this.error = null;

      if (!this.captchaValid) {
        this.showCaptchaError = true;
        this.captchaError();
        return;
      }

      // Enviar pedido
      var params = {
        email: this.identifier
      };
      this.passwordReset(params).then(response => {
        localStorage.removeItem("authToken");
        if (response != null) {
          if (response.status == 200) {
            this.complete = true;
          }
        }
      });
    },
    captchaVerified() {
      this.showCaptchaError = false;
      this.captchaValid = true;
    },
    captchaExpired() {
      this.captchaValid = false;
    },
    captchaError() {
      this.captchaValid = false;
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>
