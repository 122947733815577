<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tickets"
      :items-per-page="15"
      class="elevation-1"
    ></v-data-table>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        {
          text: "Título",
          //align: 'start',
          sortable: false,
          value: "title"
        },
        { text: "Data", value: "created_at" },
        { text: "Últ. Atualização", value: "unread_response", sortable: false },
        { text: "Últ. Atualização", value: "updated_at", sortable: false },
        { text: "Resolvido", value: "resolved", sortable: false }
      ],
      tickets: []
    };
  }
};
</script>
