import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Passwords Debug")])]),_c(VSimpleTable,{staticClass:"pa-6 ma-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Password ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Date ")])])]),_c('tbody',_vm._l((_vm.debugPasswordList),function(item){return _c('tr',{key:item},[_c('td',[_vm._v(_vm._s(item.debug_password))]),_c('td',[_vm._v(_vm._s(item.created_at))])])}),0)]},proxy:true}])}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Fechar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }