<template>
  <div>
    <div class="text-center">
      <h1 class="headline mb-2">
        Autenticar
      </h1>
      <span class="d-inline-block mb-8">Introduza os seus dados</span>
    </div>

    <v-form @submit.prevent="next">
      <v-text-field
        ref="input"
        v-model="identifier"
        class="mb-10"
        label="Email"
        name="login"
        type="text"
        hide-details="auto"
        outlined
        :disabled="disabled"
        :error-messages="error"
      />
    </v-form>

    <div class="d-flex justify-space-between">
      <v-spacer></v-spacer>
      <v-btn
        class="text-none"
        style="min-width: 88px;"
        color="primary"
        depressed
        @click="next"
      >
        Seguinte
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    error: null,
    disabled: false
  }),
  computed: {
    identifier: {
      get() {
        return this.$store.state.auth.identifier;
      },
      set(value) {
        this.$store.commit("auth/updateIdentifier", value);
      }
    }
  },
  methods: {
    next() {
      if (!this.validEmail(this.identifier)) {
        this.error = "Introduza um email válido";
        this.$refs.input.focus();
        return;
      }
      this.error = null;
      this.$emit("next", this.identifier);
      // this.$router.push({ name: 'password' })
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>
