<template>
  <v-footer absolute style="bottom:12px !important">
    <v-row no-gutters>
      <v-btn
        value="Contactos"
        color="black"
        text
        rounded
        href="https://www.octacode.pt/contactos/"
        class="my-1"
      >
        Contactos
      </v-btn>
      <v-btn
        value="About Us"
        color="black"
        text
        rounded
        href="https://www.octacode.pt/empresa/"
        class="my-1"
      >
        Quem Somos
      </v-btn>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  }
};
</script>
<style></style>
