<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title></v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Procurar"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="dialog = true">
        <v-icon left dark>
          mdi-plus
        </v-icon>
        Nova Licença
      </v-btn>
    </v-toolbar>

    <v-container fluid fill-height>
      <v-layout>
        <v-dialog v-model="dialogBackup" max-width="60vw">
          <backups-card
            v-if="dialogBackup"
            @backupCardClosed="onBackupCardClosed"
          />
        </v-dialog>
        <v-dialog v-model="dialogDebugPasswords" max-width="60vw">
          <debug-passwords-card
            v-if="dialogDebugPasswords"
            :license_id = "selectedLicenseId"
            @dialogDebugPasswordClosed="onDebugPasswordClosed"
          />
        </v-dialog>
        <v-dialog v-model="dialog" max-width="700px" persistent>
          <v-card v-if="dialog && !dialogClient">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.license_code"
                      :error-messages="errors['license_code']"
                      label="Chave da Licença"
                      readonly
                      prepend-icon="mdi-key"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      label="Produto"
                      v-model="editedItem.application_id"
                      :items="this.$octaProducts"
                      item-value="id"
                      item-text="value"
                      v-bind:disabled="editedIndex != -1"
                      prepend-icon="mdi-tag"
                    ></v-select>
                  </v-col>
                  <v-col v-if="editedIndex != -1" cols="12">
                    <v-select
                      v-model="editedItem.owner_id"
                      :items="ownedUsers"
                      :error-messages="errors['owner_id']"
                      label="Utilizador"
                      item-text="name"
                      item-value="id"
                      name="name"
                      :disabled="editedIndex != -1"
                      prepend-icon="mdi-account"
                    ></v-select>
                  </v-col>
                  <v-col cols="11">
                    <v-autocomplete
                      v-model="editedItem.owner_id"
                      :items="ownedUsers"
                      :error-messages="errors['owner_id']"
                      item-text="name"
                      item-value="id"
                      label="Utilizador"
                      name="name"
                      :loading="userLoading"
                      :disabled="userLoading"
                      prepend-icon="mdi-account"
                    />
                  </v-col>
                  <v-col
                    v-if="editedIndex == -1"
                    cols="1"
                    style="margin-top: 10px;"
                  >
                    <v-btn color="primary" fab x-small dark @click="addClient">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Limitações
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editedItem.user_limit"
                                :error-messages="errors['user_limit']"
                                label="Nº Utilizadores"
                                type="number"
                                min="1"
                                prepend-icon="mdi-account-group"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editedItem.enterprise_limit"
                                :error-messages="errors['enterprise_limit']"
                                label="Nº Empresas"
                                type="number"
                                min="1"
                                prepend-icon="mdi-briefcase"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editedItem.mobile_limit"
                                :error-messages="errors['mobile_limit']"
                                label="Dispositivos Móveis"
                                type="number"
                                min="0"
                                prepend-icon="mdi-cellphone"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!dateFromActivationDisabled">
                              <v-btn-toggle
                                mandatory
                                class="d-flex justify-center"
                              >
                                <v-btn v-model="expiryTypeDefined">
                                  <v-icon>mdi-calendar-range</v-icon>
                                  Data definida
                                </v-btn>
                                <v-btn>
                                  <v-icon>mdi-update</v-icon>
                                  A partir da ativação
                                </v-btn>
                              </v-btn-toggle>
                            </v-col>
                            <v-row v-if="expiryTypeDefined">
                              <v-col cols="8">
                                <v-menu
                                  v-model="expiryDateMenu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="placeholderExpiryDate"
                                      :error-messages="errors['expiry_date']"
                                      label="Validade"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="placeholderExpiryDate"
                                    color="blue"
                                    :min="getCurrentDate"
                                    :max="getMaxDate"
                                    @input="expiry_date_menu = false"
                                    @change="licenseDurationChanged()"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="expiryDateTotalDays"
                                  label="Total em dias"
                                  readonly
                                />
                              </v-col>
                              <v-row
                                align="center"
                                justify="space-around"
                                v-if="dateFromActivationDisabled"
                              >
                                <v-btn
                                  rounded
                                  color="primary"
                                  dark
                                  @click="triggerDateChange(-1, 'ano')"
                                >
                                  -1 ano
                                </v-btn>
                                <v-btn
                                  rounded
                                  color="secondary"
                                  dark
                                  @click="triggerDateChange(-1, 'mes')"
                                >
                                  -1 mês
                                </v-btn>
                                <v-btn
                                  rounded
                                  color="secondary"
                                  dark
                                  @click="triggerDateChange(1, 'mes')"
                                >
                                  +1 mês
                                </v-btn>
                                <v-btn
                                  rounded
                                  color="primary"
                                  dark
                                  @click="triggerDateChange(1, 'ano')"
                                >
                                  +1 ano
                                </v-btn>
                              </v-row>
                            </v-row>
                            <v-row
                              v-if="!expiryTypeDefined"
                              class="d-flex justify-center"
                            >
                              <v-col cols="4">
                                <v-select
                                  v-model="fromActivationTimeUnit"
                                  :items="timeUnits"
                                  label="Unidade Temporal"
                                  prepend-icon="mdi-calendar"
                                  @change="licenseDurationChanged()"
                                />
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="fromActivationQuantity"
                                  label="Quantidade"
                                  type="number"
                                  min="1"
                                  prepend-icon="mdi-pound"
                                  @change="licenseDurationChanged()"
                                />
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="fromActivationTotalDays"
                                  label="Total em dias"
                                  readonly
                                />
                              </v-col>
                            </v-row>
                            <v-col cols="12">
                              <v-switch
                                v-model="editedItem.update_limited"
                                hint="Licença é vitalícia, mas só permite updates até à data de validade definida"
                                persistent-hint
                                label="Limitar por Updates"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Módulos
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container>
                          <v-row>
                            <v-col cols="3">
                              <v-checkbox
                                label="Faturação"
                                v-model="moduleFaturacao"
                                @click="toggleFaturacaoModules"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                              <v-checkbox
                                label="Contabilidade"
                                v-model="moduleContabilidade"
                                @click="toggleContabilidadeModules"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="2">
                              <v-checkbox
                                label="Ativos"
                                v-model="moduleAtivos"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox
                                label="Recursos Humanos"
                                v-model="moduleRecursosHumanos"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                label="Módulos adicionais"
                                multiple
                                deletable-chips
                                small-chips
                                v-model="editedModules"
                                :items="octaModules"
                                item-text="value"
                                item-value="id"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="editedIndex != -1">
                      <v-expansion-panel-header>
                        Controlo
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container>
                          <v-chip-group
                            class="mx-auto"
                            v-model="editedItem.activated"
                            :items="licenseStatusValues"
                            item-value="id"
                            item-text="value"
                            mandatory
                            column
                          >
                            <v-chip filter outlined value="0">
                              Por ativar
                            </v-chip>
                            <v-chip filter outlined value="1">
                              Ativa
                            </v-chip>
                            <v-chip filter outlined value="2">
                              Desativada
                            </v-chip>
                            <v-chip filter outlined value="3">
                              Bloqueada
                            </v-chip>
                          </v-chip-group>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="primary" depressed text @click="save">
                {{ formSaveButton }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <client-card
            @userId="onSuccess"
            @operationCancelled="onCancel"
            v-if="dialogClient"
          />
        </v-dialog>
        <v-flex style="overflow: auto">
          <v-data-table
            :headers="headers"
            :items="licensesVisualList"
            :search="search"
            sort-by="id"
            :loading="licenseListLoading"
            loading-text="A carregar dados..."
            :footer-props="{
              'items-per-page-options': [10, 25, 50]
            }"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="copyLicenseToClipboard(item)"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copiar chave</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="showLicenseBackups(item)"
                  >
                    mdi-cloud-upload-outline
                  </v-icon>
                </template>
                <span>Ver backups</span>
              </v-tooltip>
              <v-tooltip bottom v-if="user.data.role === 'admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="showLicenseDocuments(item)"
                  >
                    mdi-file-document-outline
                  </v-icon>
                </template>
                <span>Consultar documentos</span>
              </v-tooltip>
              <v-tooltip bottom v-if="user.data.role === 'admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="showDebugPasswords(item)"
                  >
                    mdi-lock
                  </v-icon>
                </template>
                <span>Ver passwords debug</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <p class="pt-4">Sem dados para mostrar</p>
              <v-btn class="mb-6" color="primary" @click="initialize">
                Update
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        bottom
        right
        :color="snackbarStatus"
      >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ClientCard from "@/components/Cards/ClientCard.vue";
import BackupsCard from "@/components/Cards/BackupsCard.vue";
import DebugPasswordsCard from "../components/Cards/DebugPasswordsCard.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    ClientCard,
    BackupsCard,
    DebugPasswordsCard
  },

  data: () => ({
    search: "",
    dialog: false,
    dialogClient: false,
    dialogBackup: false,
    dialogDebugPasswords: false,
    expiryDateMenu: false,
    licenseListLoading: true,
    userLoading: false,
    dateFromActivationDisabled: false, // Define se o método de data "a partir de ativação" deve ser desligado (para actualizações de licenças, por exemplo)
    expiryTypeDefined: false, // Define se o tipo de método usado foi a activação, ou uma data definida (false=data definida)
    ownedUsers: [],
    licenseStatusValues: [
      { id: 0, value: "Por ativar" },
      { id: 1, value: "Ativa" },
      { id: 2, value: "Desativada" },
      { id: 3, value: "Bloqueada" }
    ],
    octaModules: [],
    headers: [
      { text: "Id", value: "id", align: "center" },
      { text: "Chave", value: "license_code", align: "start", sortable: false },
      { text: "Produto", value: "application_id", align: "center" },
      { text: "Cliente", value: "client.name", align: "center" },
      { text: "Criador", value: "provider.name", align: "center" },
      {
        text: "Validade",
        value: "expiry_date",
        align: "center",
        sortable: false
      },
      { text: "Estado", value: "license_status", align: "center" },
      { text: "Ações", value: "actions", sortable: false, align: "center" }
    ],
    licenses: [],
    licensesVisualList: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      license_code: "",
      application_id: 1,
      user_limit: 1,
      enterprise_limit: 1,
      mobile_limit: 0,
      update_limited: false,
      modules: "00000000000000000000000000000000",
      activated: 0,
      license_status: "",
      license_duration: 0,
      license_hash: "",
      license_guid: "",
      activation_date: "1900-01-01 00:00:00",
      expiry_date: new Date().toISOString().substr(0, 10)
    },
    placeholderExpiryDate: new Date().toISOString().substr(0, 10),
    moduleFaturacao: false,
    moduleContabilidade: false,
    moduleAtivos: false,
    moduleRecursosHumanos: false,
    editedModules: [],
    defaultItem: {
      id: 0,
      license_code: "",
      application_id: 1,
      user_limit: 1,
      enterprise_limit: 1,
      mobile_limit: 0,
      update_limited: false,
      modules: "00000000000000000000000000000000",
      activated: 0,
      license_status: "",
      license_duration: 0,
      license_hash: "",
      license_guid: "",
      activation_date: "1900-01-01 00:00:00",
      expiry_date: new Date().toISOString().substr(0, 10)
    },
    timeUnits: ["Dias", "Meses", "Anos"],
    fromActivationQuantity: 1,
    fromActivationTimeUnit: "Dias",
    fromActivationTotalDays: "1 dias",
    expiryDateTotalDays: "1 dias",
    errors: [],
    snackbar: false,
    snackbarText: "",
    snackbarStatus: "success",
    selectedLicenseId: 0
  }),

  props: {},

  computed: {
    ...mapGetters("auth", { user: "user" }),
    formTitle() {
      return this.editedIndex === -1 ? "Nova Licença" : "Editar Licença";
    },
    formSaveButton() {
      return this.editedIndex === -1 ? "Criar" : "Guardar";
    },
    getCurrentDate() {
      return new Date().toISOString().slice(0, 10);
    },
    getMaxDate() {
      return new Date(2099, 12, 31).toISOString().slice(0, 10);
    }
  },

  mounted() {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogClient(val) {
      val || this.closeClient();
    }
  },

  created() {
    this.initialize();
    // Remover coluna "Criador" se não for um Admin
    if(this.user.data.role != 'admin'){
      var headerCreator = this.headers.find(i => i.text === 'Criador');
      if(headerCreator != null){
        this.headers.splice(this.headers.indexOf(headerCreator), 1)
      }
    }
  },

  methods: {
    ...mapActions("license", ["create", "update", "generateKey", "getOwnedLicenses"]),
    ...mapActions("client", ["getOwnedClients"]),
    ...mapMutations("backup", ["setLicenseData"]),
    ...mapMutations("documentstorage", ["setSelectedLicense"]),

    fetchNewLicenseKey: function() {
      this.generateKey().then(response => {
        if (this.editedIndex == -1) {
          this.editedItem.license_code = response.data.attributes;
        }
      });
    },
    fetchOwnClients: function() {
      this.getOwnedClients().then(response => {
        this.ownedUsers = JSON.parse(JSON.stringify(response));
      });
    },
    fetchOwnLicenses: function() {
      this.getOwnedLicenses().then(response => {
        var jsonLicenses = JSON.parse(JSON.stringify(response));
        jsonLicenses.forEach(element => {
          const [expiry_date, garbage] = element.expiry_date.split(" ");
          element.expiry_date = expiry_date;
        });

        this.licenses = jsonLicenses;

        this.licensesVisualList = JSON.parse(JSON.stringify(this.licenses));
        var currentDate = new Date();

        this.licensesVisualList.forEach(element => {
          element.application_id = this.$octaProducts[
            element.application_id - 1
          ].value;

          if (element.activated == 0) {
            element.license_status = this.licenseStatusValues[0].value;
          } else if (element.activated == 1) {
            element.license_status = this.licenseStatusValues[1].value;
          } else if (element.activated == 2) {
            element.license_status = this.licenseStatusValues[2].value;
          } else {
            element.license_status = this.licenseStatusValues[3].value;
          }

          if (
            new Date(element.expiry_date) < currentDate &&
            element.activated != 0
          ) {
            element.license_status = "Expirada";
          }
        });

        this.licenseListLoading = false;
      });
    },
    initialize() {
      this.fetchOwnLicenses();
      this.fetchOwnClients();
      this.fetchNewLicenseKey();
    },
    editItem(item) {
      this.editedIndex = this.licensesVisualList.indexOf(item);
      this.editedItem = Object.assign({}, this.licenses[this.editedIndex]);

      this.$nextTick(() => {
        if (this.editedIndex != -1) {
          this.dateFromActivationDisabled = true;
          this.expiryTypeDefined = true;
          this.editedItem.owner_id = parseInt(this.editedItem.owner_id);
          this.editedItem.application_id = parseInt(
            this.editedItem.application_id
          );
          this.editedItem.update_limited =
            this.editedItem.update_limited == "0" ? false : true;
          this.placeholderExpiryDate = new Date(this.editedItem.expiry_date)
            .toISOString()
            .substr(0, 10);
          this.editedModules = this.parseExistingModules(
            this.editedItem.modules
          );

          var currentDate = new Date().getTime();
          var expiryDate = new Date(this.editedItem.expiry_date).getTime();
          var daysDiff = Math.floor(
            (expiryDate - currentDate) / (1000 * 3600 * 24)
          );

          if (expiryDate == new Date("1900-01-01")) {
            // Não activada
            // TODO
          } else {
            this.expiryDateTotalDays = daysDiff + " dias";
            if (daysDiff > 0) {
              // Activada e válida
            } else {
              // Activada mas expirada
            }
          }
        }
      });

      this.dialog = true;
    },
    showLicenseBackups(item) {
      var licenseIndex = this.licensesVisualList.indexOf(item);
      var licenseData = Object.assign({}, this.licenses[licenseIndex]);
      this.setLicenseData(licenseData);
      this.dialogBackup = true;
    },
    showLicenseDocuments(item) {
      var licenseIndex = this.licensesVisualList.indexOf(item);
      var licenseData = Object.assign({}, this.licenses[licenseIndex]);

      this.setLicenseData(licenseData);

      this.$nextTick(() => {
        this.setSelectedLicense(licenseData.license_code);
        this.$router.push(`documents`);
      });
    },
    showDebugPasswords(item) {      
      var licenseIndex = this.licensesVisualList.indexOf(item);
      var licenseData = Object.assign({}, this.licenses[licenseIndex]);

      this.selectedLicenseId = licenseData.id;

      this.dialogDebugPasswords = true;
    },
    save() {
      // Formatar data antes de enviar
      this.editedItem.modules = this.parseSelectedModules(this.editedModules);
      this.editedItem.expiry_date = this.formatDate(this.placeholderExpiryDate);
      this.editedItem.activation_date = this.formatDate(
        this.editedItem.activation_date
      );
      this.errors = {};
      if (this.editedIndex == -1) {
        // Nova licença
        if (this.expiryTypeDefined) {
          this.editedItem.license_duration = this.expiryDateTotalDays.replace(
            " dias",
            ""
          );
        } else {
          this.editedItem.license_duration = this.fromActivationTotalDays.replace(
            " dias",
            ""
          );
        }
        this.create(this.editedItem)
          .then(response => {
            // Adicionado com sucesso
            this.$store.commit("setErrors", []);
            this.close();
            this.$nextTick(() => {
              this.showSnackbar("Licença criada com sucesso", "success");
              this.fetchOwnLicenses();
            });
          })
          .catch(error => {
            this.showSnackbar(error, "error");
          });
      } else {
        // Licença editada
        this.$store
          .dispatch("license/update", this.editedItem)
          .then(response => {
            // Atualizado com sucesso
            this.$store.commit("setErrors", []);
            this.close();
            this.$nextTick(() => {
              this.showSnackbar("Licença atualizada", "success");
              this.fetchOwnLicenses();
            });
          })
          .catch(error => {
            this.showSnackbar(error, "error");
          });
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.fetchNewLicenseKey();
        // Reset em todas as opções
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.moduleFaturacao = false;
        this.moduleContabilidade = false;
        this.moduleAtivos = false;
        this.moduleRecursosHumanos = false;
        this.octaModules = [];
        this.editedModules = [];
        this.placeholderExpiryDate = new Date().toISOString().substr(0, 10);
        this.dateFromActivationDisabled = false;
      });
    },
    closeClient() {
      this.dialogClient = false;
    },
    addClient() {
      this.dialogClient = true;
    },
    onSuccess(id) {
      this.dialogClient = false;
      this.userLoading = true;
      // Selecionar o cliente criado
      this.getOwnedClients().then(response => {
        this.ownedUsers = JSON.parse(JSON.stringify(response));
        this.editedItem.owner_id = id;
        this.userLoading = false;
      });
    },
    onCancel(operationCancelled) {
      this.dialogClient = false;
    },
    parseSelectedModules(selectedModules) {
      var modulesStr = "00000000000000000000000000000000000000000";

      if (this.moduleContabilidade) {
        modulesStr = this.replaceAt(modulesStr, 7, "1");
      }
      if (this.moduleFaturacao) {
        modulesStr = this.replaceAt(modulesStr, 8, "1");
      }
      if(this.moduleAtivos) {
        modulesStr = this.replaceAt(modulesStr, 13, "1");
      }
      if (this.moduleRecursosHumanos) {
        modulesStr = this.replaceAt(modulesStr, 12, "1");
      }

      var jsonModules = JSON.parse(JSON.stringify(selectedModules));
      jsonModules.forEach(element => {
        var elementIndex = JSON.parse(JSON.stringify(element));
        modulesStr = this.replaceAt(modulesStr, elementIndex, "1");
      });

      return modulesStr;
    },
    parseExistingModules(modulesStr) {
      if (modulesStr.charAt(7) === "1") {
        this.moduleContabilidade = true;
        this.toggleContabilidadeModules();
      }
      if (modulesStr.charAt(8) === "1") {
        this.moduleFaturacao = true;
        this.toggleFaturacaoModules();
      }
      if (modulesStr.charAt(13) === "1") {
        this.moduleAtivos = true;
      }
      if (modulesStr.charAt(12) === "1") {
        this.moduleRecursosHumanos = true;
      }

      var existingModules = [];
      for (var i = 0; i < modulesStr.length; i++) {
        if (modulesStr.charAt(i) === "1") {
          var modFaturacao = this.$octaModulesFaturacao.find(
            elem => elem.id === i
          );
          var modContabilidade = this.$octaModulesContabilidade.find(
            elem => elem.id === i
          );

          if (modFaturacao != undefined) {
            modFaturacao = JSON.parse(JSON.stringify(modFaturacao));
            if (modFaturacao.id === i) {
              existingModules.push(modFaturacao.id);
            }
          }
          if (modContabilidade != undefined) {
            modContabilidade = JSON.parse(JSON.stringify(modContabilidade));
            if (modContabilidade.id === i) {
              existingModules.push(modContabilidade.id);
            }
          }
        }
      }
      return existingModules;
    },
    toggleFaturacaoModules() {
      if (this.moduleFaturacao) {
        this.octaModules.push(...this.$octaModulesFaturacao);
      } else {
        // Retirar os módulos selecionados da lista actual
        this.octaModules = this.octaModules.filter(
          i => !this.$octaModulesFaturacao.some(j => j.id === i.id)
        );
        this.editedModules = this.editedModules.filter(
          i => !this.$octaModulesFaturacao.some(j => j.id === i.id)
        );
      }
    },
    toggleContabilidadeModules() {
      if (this.moduleContabilidade) {
        this.octaModules.push(...this.$octaModulesContabilidade);
      } else {
        // Retirar os módulos selecionados da lista actual
        this.octaModules = this.octaModules.filter(
          i => !this.$octaModulesContabilidade.some(j => j.id === i.id)
        );
        this.editedModules = this.editedModules.filter(
          i => !this.$octaModulesContabilidade.some(j => j.id === i.id)
        );
      }
    },
    licenseDurationChanged() {
      var daysDiffFromActivation = 0;
      var daysDiffExpiry = 0;

      if (this.fromActivationTimeUnit == this.timeUnits[0]) {
        // Dias
        daysDiffFromActivation = this.fromActivationQuantity;
      } else if (this.fromActivationTimeUnit == this.timeUnits[1]) {
        // Meses
        daysDiffFromActivation = this.fromActivationQuantity * 30;
      } else {
        // Anos
        daysDiffFromActivation = this.fromActivationQuantity * 365;
      }
      this.fromActivationTotalDays = daysDiffFromActivation + " dias";

      var currentDate = new Date().getTime();
      var datePickerValue = new Date(this.placeholderExpiryDate);
      daysDiffExpiry = Math.floor(
        (datePickerValue - currentDate) / (1000 * 3600 * 24)
      );
      this.expiryDateTotalDays = daysDiffExpiry + " dias";
    },
    triggerDateChange(value, timeframe) {
      var currentDate = new Date(this.placeholderExpiryDate);
      if (timeframe == "mes") {
        currentDate.setMonth(currentDate.getMonth() + value);
      } else {
        // ano
        currentDate.setFullYear(currentDate.getFullYear() + value);
      }
      this.placeholderExpiryDate = currentDate.toISOString().substr(0, 10);
      this.licenseDurationChanged();
    },
    copyLicenseToClipboard(item) {
      var text = item.license_code;
      navigator.clipboard.writeText(text).then(
        function() {},
        function(err) {}
      );
    },
    showSnackbar(text, status) {
      this.snackbar = true;
      this.snackbarText = text;
      this.snackbarStatus = status;
    },
    formatDate(date) {
      if (!date) return null;

      const [filteredDate, garbage] = date.split(" ");
      const [year, month, day] = filteredDate.split("-");
      return `${year}-${month}-${day} 00:00:01`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    replaceAt(str, index, char) {
      return str.substr(0, index) + char + str.substr(index + char.length);
    },
    onBackupCardClosed(backupCardClosed) {
      this.dialogBackup = false;
    },
    onDebugPasswordClosed() {
      this.dialogDebugPasswords = false;
    }
  }
};
</script>
