<template>
  <v-card v-on:close-dialog="close">
    <v-responsive>
      <v-card-title class="indigo white--text text-h5">
        {{ formTitle }}
      </v-card-title>
      <v-sheet class="pa-4 indigo white--text">
        <v-text-field
          v-model="search"
          label="Pesquisa"
          flat
          dark
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </v-sheet>
      <v-row class="pa-4" justify="space-between">
        <v-col cols="5">
          <v-treeview
            :active.sync="active"
            :items="items"
            :load-children="fetchBackups"
            :search="search"
            :filter="filter"
            activatable
            color="primary"
            open-on-click
            transition
            dense
            return-object
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="!item.children">
                mdi-backup-restore
              </v-icon>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col class="d-flex text-center">
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!selectedBackup"
              class="text-h6 grey--text text--lighten-1 font-weight-light"
              style="align-self: center; width: 100%"
            >
              {{ messageCaption }}
            </div>
            <v-card
              v-else
              :key="selectedBackup.id"
              class="pt-6 mx-auto"
              flat
              max-width="400"
            >
              <v-card-text>
                <h3 class="text-h5 mb-2">
                  {{ selectedBackup.name }}
                </h3>
                <div class="blue--text mb-2">
                  {{ formatDate(selectedBackup.LastModified) }}
                </div>
                <div class="blue--text subheading font-weight-bold">
                  {{ formatBytes(selectedBackup.Size) }}
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-row align="center" justify="center" tag="v-card-text">
                <v-col>
                  <v-btn depressed color="primary" @click="downloadBackup">
                    Download
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn depressed color="error" @click="deleteBackup">
                    Eliminar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-responsive>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "backups-card",

  data: () => ({
    licDetails: {
      id: 0,
      license_code: ""
    },
    search: null,
    messageCaption: "A carregar as empresas...",

    companyList: [],

    active: [],
    backups: [],
    errors: [],
    backupIndex: 0
  }),

  mounted() {
    this.licDetails = this.licenseData;
    this.messageCaption = "A carregar as empresas...";
    this.fetchCompanies();
  },

  watch: {
    selectedBackup: []
  },

  computed: {
    ...mapGetters("backup", { licenseData: "getLicenseData" }),

    formTitle() {
      var title = "Seguranças de " + this.licDetails.license_code;
      return title;
    },

    items() {
      var itemsArray = [];
      var listCompanies = JSON.parse(JSON.stringify(this.companyList));

      listCompanies.forEach((company, index) => {
        var item = {
          id: index + 10000,
          name: company.name + " ",
          children: this.backups[index]
        };

        itemsArray.push(item);
      });

      return itemsArray;
    },

    selectedBackup() {
      if (!this.active.length) return undefined;

      var active = this.active[0];
      const id = active.id;
      var key = active.Key;

      var result = [];
      this.backups.forEach(index => {
        var backup = index.find(b => b.id == id && b.Key.includes(key));
        if (backup != null) {
          result = backup;
        }
      });

      return result;
    },
    filter() {
      return (item, search, textKey) =>
        item[textKey].toUpperCase().indexOf(search.toUpperCase()) > -1;
    }
  },

  methods: {
    ...mapActions("backup", ["companies", "list", "download", "delete"]),
    fetchCompanies() {
      var params = {
        license_code: this.licDetails.license_code
      };

      this.companies(params)
        .then(res => JSON.parse(JSON.stringify(res)))
        .then(json => {
          if (json.length == 0) {
            this.messageCaption = "Não há seguranças para esta licença";
          } else {
            this.messageCaption =
              "Selecione uma empresa e de seguida um ficheiro";
            json.forEach(backupObserver => {
              var company = JSON.parse(JSON.stringify(backupObserver));

              var companyName = company.substr(0, company.length - 1);
              var company = {
                name: companyName
              };
              if (
                this.companyList.filter(c => c.name === companyName).length ===
                0
              ) {
                this.backups.push([]);
                this.companyList.push(company);
              }
            });
          }
        });
    },

    async fetchBackups(item) {
      var params = {
        license_code: this.licDetails.license_code,
        company_name: item.name.trim()
      };

      return this.list(params)
        .then(res => JSON.parse(JSON.stringify(res)))
        .then(json => {
          if (item.children == undefined) {
            item.children = [];
          }
          json.forEach((backup, index) => {
            backup["id"] = this.backupIndex;
            backup["name"] = this.formatBackupKey(backup["Key"]);
            item.children.push(backup);
            this.backupIndex++;
          });
        })
        .catch(err => {
          // TODO
        });
    },

    close() {
      this.$emit("backupCardClosed", 1);
    },

    downloadBackup(item) {
      var backup = this.selectedBackup;
      var company = this.selectedBackup.Key.split("/")[1];

      var params = {
        company_name: company,
        aws_backup_key: backup.name,
        license_code: this.licDetails.license_code
      };

      this.download(params)
        .then(res => JSON.parse(JSON.stringify(res)))
        .then(downloadUrl => {
          window.location.href = downloadUrl;
        });
    },

    deleteBackup() {
      var backup = this.selectedBackup;

      var backup = this.selectedBackup;
      var company = this.selectedBackup.Key.split("/")[1];

      var params = {
        company_name: company,
        aws_backup_key: backup.name,
        license_code: this.licDetails.license_code
      };

      this.delete(params)
        .then(res => JSON.parse(JSON.stringify(res)))
        .then(res => {
          if (res == "OK") {
            // Remover da lista
            this.backups.forEach(index => {
              var backupIndex = index.findIndex(b =>
                b.Key.includes(this.selectedBackup.Key)
              );
              if (backupIndex != -1) {
                index.splice(backupIndex, 1);
              }
            });
            this.active = [];
          }
        });
    },

    formatBackupKey(key) {
      var split = key.split("/");
      return split[split.length - 1];
    },
    formatDate(date) {
      return date.replace("T", " ").replace("+00:00", "");
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    indexOfArray(val, array) {
      var hash = {};
      for (var i = 0; i < array.length; i++) {
        hash[array[i]] = i;
      }
      return hash.hasOwnProperty(val) ? hash[val] : -1;
    }
  }
};
</script>
