<template>
  <div>
    <v-dialog
      v-model="dialogDeleteUser"
      width="auto"
    >
      <v-card>
        <v-card-title>
          Pretende eliminar este utilizador?
        </v-card-title>
        <v-card-text>
          Esta acção não poderá ser desfeita.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialogDeleteUser = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteUser()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card :loading="cardLoading">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedClient.email"
                :error-messages="errors['email']"
                label="Email"
                v-bind:disabled="
                  clientIndex != -1 && this.user.data.role !== 'admin'
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedClient.name"
                :error-messages="errors['name']"
                label="Nome do responsável"
                v-bind:disabled="
                  clientIndex != -1 && this.user.data.role !== 'admin'
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedClient.company"
                :error-messages="errors['company']"
                label="Empresa"
                v-bind:disabled="
                  clientIndex != -1 && this.user.data.role !== 'admin'
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                class="pt-8"
                v-model="selectedLicenseCode"
                :items="licenseList"
                label="Licença"
                dense
                outlined
                @input="selectedLicenseChanged"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Empresas associadas"
                multiple
                deletable-chips
                small-chips
                v-model="editedCompanies"
                :items="accountantCompanies"
                item-text="value"
                item-value="id"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions
        class="justify-center"
        v-if="clientIndex != -1 && user.data.role == 'admin'"
      >
        <v-btn color="darken-1" @click="resendActivationButton">
          Reenviar ativação
        </v-btn>
        <v-btn color="darken-1" @click="resetPassword"> Reset Password </v-btn>
      </v-card-actions>

      <v-card-actions class="mt-4">
        <v-btn v-if="clientIndex != -1" 
          v-bind:disabled="cardLoading" 
          color="error" 
          @click="dialogDeleteUser = true"
        >
          Eliminar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-bind:disabled="cardLoading"
          color="blue darken-1"
          text
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-bind:disabled="cardLoading"
          color="primary"
          depressed
          text
          @click="saveClient"
        >
          {{ formSaveButton }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar"
        :timeout="6000"
        bottom
        right
        :color="snackbarStatus"
      >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "accountant-client-card",

  data: () => ({
    editedClient: {
      id: 0,
      creator_id: 0,
      name: "",
      email: "",
      company: "",
      license_code: '',
      company_list: [],
    },
    accountantCompanies: [],
    editedCompanies: [],
    clientIndex: -1,
    cardLoading: false,
    errors: [],
    snackbar: false,
    snackbarText: "",
    snackbarStatus: "success",
    selectedLicenseCode: "",
    licenseList: [],
    dialogDeleteUser: false,
  }),

  mounted() {
    this.editedClient = this.clientData;
    this.clientIndex = this.clientIndexData;
  },

  created() {
    this.fetchOwnedLicenses();
    this.fetchAccountantClientData();
  },

  computed: {
    ...mapGetters("auth", { user: "user" }),
    ...mapGetters("client", {
      clientData: "getClientData",
      clientIndexData: "getClientIndex",
    }),
    formTitle() {
      return this.clientIndex == -1 ? "Novo Cliente" : "Editar Cliente";
    },
    formSaveButton() {
      return this.clientIndex == -1 ? "Criar" : "Guardar";
    },
  },

  methods: {
    ...mapActions("documentstorage", ["index"]),
    ...mapActions("client", ["store", "update", "accountant", "deleteUserRequest"]),
    ...mapActions("license", ["getOwnedLicenses"]),
    ...mapActions("auth", ["passwordReset", "resendActivation"]),
    fetchOwnedLicenses() {
      this.getOwnedLicenses().then((response) => {
        this.isLoading = false;
        var jsonLicenses = response;

        jsonLicenses.forEach((element) => {
          this.licenseList.push(element.license_code);
          const [expiry_date, garbage] = element.expiry_date.split(" ");
          element.expiry_date = expiry_date;
        });

        this.licenses = jsonLicenses;
      });
    },
    fetchAccountantClientData() {
      if (this.clientData.id == 0) {
        return;
      }
      var params = {
        client_id: this.clientData.id,
      };

      // Obter dados
      this.accountant(params).then(response => {
        if(response != [] && response['error'] == undefined){
          // Selecionar dados existentes
          this.selectedLicenseChanged(response["license"]["license_code"]);
          var companies = JSON.parse(response["company_list"]);
          this.editedCompanies = companies;
        }else{
          // Selecionar primeira licença e obter dados dessa
          if (this.licenses.length > 0) {
            this.selectedLicenseChanged(this.licenses[0].license_code);
          }
        }
      });
    },
    selectedLicenseChanged(license) {
      this.accountantCompanies = [];
      this.selectedLicenseCode = license;
      this.selectedLicense = this.licenses.find(
        (i) => i.license_code == license
      );

      var params = {
        client_id: this.clientData.id,
        license_code: license
      };

      this.index(params)
        .then(res => JSON.parse(JSON.stringify(res)))
        .then(json => {
          this.accountantCompanies = [];
          json.forEach(lineObserver => {
            var line = JSON.parse(JSON.stringify(lineObserver));
            if (!this.accountantCompanies.includes(line)) {
              this.accountantCompanies.push(line);
            }
          });
          this.selectedCompany = this.accountantCompanies[0];
        });
    },
    saveClient() {
      this.errors = {};

      this.editedClient.license_code = this.selectedLicenseCode;
      this.editedClient.company_list = this.editedCompanies;

      if (!this.validEmail(this.editedClient.email)) {
        this.errors["email"] = "Introduza um email válido";
        this.$refs.input.focus();
        return;
      }

      this.cardLoading = true;
      if (this.clientIndex == -1) {
        // Novo cliente
        this.store(this.editedClient)
          .then((response) => {
            this.cardLoading = false;
            // Adicionado com sucesso
            this.success(response.data.attributes.id);
          })
          .catch((_) => {
            this.cardLoading = false;
            var errors = this.$store.state.errors;
            if (errors.length > 0) {
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
              this.showSnackbar(this.errors, "error");
            } else {
              this.showSnackbar("Erro inesperado, contacte o suporte", "error");
            }
            this.$store.commit("setErrors", []);
          });
      } else {
        // Cliente editado
        this.update(this.editedClient)
          .then((response) => {
            this.cardLoading = false;
            var errors = this.$store.state.errors;
            if (errors.length === 0) {
              // Adicionado com sucesso
              this.success(-1);
            } else {
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
              var errorStr = Object.values(this.errors)[0].toString();
              errorStr = errorStr.replace('["').replace('"]');
              this.showSnackbar(errorStr, "error");
            }
            this.$store.commit("setErrors", []);
          })
          .catch((error) => {
            this.cardLoading = false;
            this.showSnackbar(error, "error");
          });
      }
    },
    resendActivationButton() {
      var payload = {
        user_id: this.editedClient.id,
      };

      this.resendActivation(payload)
        .then((response) => {
          // TODO
        })
        .catch((err) => {});
    },
    resetPassword() {
      // Enviar pedido
      var params = {
        email: this.editedClient.email,
      };
      this.passwordReset(params).then((response) => {
        if (response != null) {
          if (response.status == 200) {
            this.complete = true;
          }
        }
      });
    },
    deleteUser() {
      // Show dialog
      this.deleteUserRequest(this.editedClient)
          .then((response) => {
            this.cardLoading = false;
            var errors = this.$store.state.errors;
            if (errors.length === 0) {
              // Adicionado com sucesso
              this.success(-1);
            } else {
              // Existem erros
              this.errors = JSON.parse(JSON.stringify(errors));
              var errorStr = Object.values(this.errors)[0].toString();
              errorStr = errorStr.replace('["').replace('"]');
              this.showSnackbar(errorStr, "error");
            }
            this.$store.commit("setErrors", []);
          })
          .catch((error) => {
            this.cardLoading = false;
            this.showSnackbar(error, "error");
          });
    },
    success(id) {
      this.$emit("userId", id);
    },
    close() {
      this.$emit("operationCancelled", -1);
    },
    showSnackbar(text, status) {
      this.snackbar = true;
      this.snackbarText = text;
      this.snackbarStatus = status;
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>
