import axios from "axios";

const state = {
  selectedLicenseCode: ""
};
const getters = {
  selectedLicense: state => state.selectedLicenseCode
};
const mutations = {
  setSelectedLicense(state, licenseCode) {
    state.selectedLicenseCode = licenseCode;
  },
};

const actions = {
  index(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/documentstorage", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  listObjects(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/documentstorage/listObjects", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  download(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/documentstorage/download", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  generateS3Upload(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/documentstorage/upload", { params })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  move(_, payload) {    
    return new Promise((resolve, reject) => {
      axios
        .post("/documentstorage/move", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  delete(_, params) {    
    return new Promise((resolve, reject) => {
      axios
        .delete("/documentstorage/delete", { params })
        .then(response => resolve(response.data.attributes))
        .catch(error => {
          //localStorage.removeItem("authToken");
          reject(error);
        });
    });
  },
  notify(_, payload) {    
    return new Promise((resolve, reject) => {
      axios
        .post("/documentstorage/notify", payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
